import React, { useState } from 'react';
import styles from './scheduler.module.css';
import SchedulerSidebar from './SchedulerSidebar';
import SchedulerMenubar from './SchedulerMenubar';
import SchedulerCalendar from './SchedulerCalendar';
import MiniTechProfileModal from "./MiniTechProfileModal";
import { ScheduleProvider } from "./context/ScheduleContext";
import TimeBlockerModal from "./TimeBlockerModal";
import SMSModal from './components/SMSModal/SMSModal';
const SchedulePage = () => {
  const [isGridLocked, setIsGridLocked] = useState(true);
  const [workspaceItems, setWorkspaceItems] = useState([]);
  const [schedules, setSchedules] = useState([
    {
      id: 1,
      startIndex: 2,
      span: 2,
      status: 'scheduled',
      rowIndex: 1,
      location: 'Liverpool',
      issue: 'Leaking Tap/Toilet',
    },
    {
      id: 2,
      startIndex: 0,
      span: 2,
      status: 'completed',
      rowIndex: 4,
      location: 'Liverpool',
      issue: 'Leaking Tap/Toilet',
    },
    {
      id: 3,
      startIndex: 3,
      span: 2,
      status: 'started',
      rowIndex: 2,
      location: 'Manchester',
      issue: 'Faulty Lighting',
    },
    {
      id: 4,
      startIndex: 5,
      span: 2,
      status: 'ongoing',
      rowIndex: 3,
      location: 'Birmingham',
      issue: 'Leaking Pipe',
    },
    {
      id: 5,
      startIndex: 7,
      span: 2,
      status: 'scheduled',
      rowIndex: 1,
      location: 'London',
      issue: 'Faulty Electrical Outlet',
    },
    {
      id: 6,
      startIndex: 2,
      span: 2,
      status: 'completed',
      rowIndex: 9,
      location: 'Leeds',
      issue: 'Clogged Drain',
    },
    {
      id: 7,
      startIndex: 3,
      span: 3,
      status: 'completed',
      rowIndex: 10,
      location: 'Liverpool',
      issue: 'Leaking Tap/Toilet',
    },
  ]);

  const workers = [
    {
      id: 1,
      firstName: 'Ralph',
      lastName: 'Edwards',
      status: 'Ongoing',
      apprentices: []
    },
    {
      id: 2,
      firstName: 'John',
      lastName: 'Smith',
      status: 'Available',
      apprentices: [
        { id: 4, imageUrl: '/images/scheduler/apprentice.png' },
        { id: 5, imageUrl: '/images/scheduler/apprentice.png' }
      ]
    },
    {
      id: 3,
      firstName: 'Alice',
      lastName: 'Johnson',
      status: 'Available',
      apprentices: []
    },
    {
      id: 4,
      firstName: 'Michael',
      lastName: 'Brown',
      status: 'Available',
      apprentices: []
    },
    {
      id: 5,
      firstName: 'Emma',
      lastName: 'Davis',
      status: 'Ongoing',
      apprentices: [
        { id: 6, imageUrl: '/images/scheduler/apprentice.png' }
      ]
    },
    {
      id: 6,
      firstName: 'Sophia',
      lastName: 'Wilson',
      status: 'Available',
      apprentices: [
        { id: 7, imageUrl: '/images/scheduler/apprentice.png' }
      ]
    },
    {
      id: 7,
      firstName: 'Liam',
      lastName: 'Martinez',
      status: 'Ongoing',
      apprentices: []
    },
    {
      id: 8,
      firstName: 'Olivia',
      lastName: 'Garcia',
      status: 'Ongoing',
      apprentices: [
        { id: 8, imageUrl: '/images/scheduler/apprentice.png' },
        { id: 9, imageUrl: '/images/scheduler/apprentice.png' }
      ]
    },
    {
      id: 9,
      firstName: 'Noah',
      lastName: 'Anderson',
      status: 'Available',
      apprentices: []
    },
  ];

  const handleWorkspaceDragOver = e => {
    e.preventDefault();
    e.currentTarget.classList.add(styles.workspaceDragOver);
  };

  const handleWorkspaceDragLeave = e => {
    e.currentTarget.classList.remove(styles.workspaceDragOver);
  };

  const handleWorkspaceDrop = e => {
    e.preventDefault();
    e.currentTarget.classList.remove(styles.workspaceDragOver);

    if (workspaceItems.length >= 10) {
      console.warn('Maximum workspace items reached (2 rows)');
      return;
    }

    try {
      const schedule = JSON.parse(e.dataTransfer.getData('application/json'));
      console.log('Dropped schedule:', schedule);

      // Check if item is already in workspace
      const isAlreadyInWorkspace = workspaceItems.some(item => item.id === schedule.id);
      if (isAlreadyInWorkspace) {
        console.warn('Item already exists in workspace');
        return;
      }

      // Add to workspace
      setWorkspaceItems(prev => [...prev, schedule]);

      // Remove from calendar if it came from calendar
      if (schedule.fromCalendar) {
        setSchedules(prev => prev.filter(item => item.id !== schedule.id));
      }
    } catch (error) {
      console.error('Error parsing drag data:', error);
    }
  };

  return (
    <ScheduleProvider>
      <div className={styles.schedulerContainer}>
        <SchedulerSidebar />
        <div className={styles.schedulerContent}>
          <div className={styles.workspaceContainer}>
            <div className={styles.workspaceHeader}>
              <img src="/images/scheduler/workspace.svg" alt="workspace" />
              <p>Workspace</p>
            </div>
            <div
              className={styles.workspaceContent}
              onDragOver={handleWorkspaceDragOver}
              onDragLeave={handleWorkspaceDragLeave}
              onDrop={handleWorkspaceDrop}
            >
              {workspaceItems.map((item, index) => (
                <div
                  key={index}
                  className={styles.workspaceItem}
                  draggable="true"
                  onDragStart={e => {
                    e.dataTransfer.setData(
                      'application/json',
                      JSON.stringify({
                        ...item,
                        fromWorkspace: true,
                      })
                    );
                  }}
                >
                  <div className={styles.flexMinutes}>
                    <p className={styles.workspaceRegular}>{item.location || 'Unknown Location'}</p>
                    <p className={styles.workspaceRegular}>15 mins ago</p>
                  </div>
                  <p className={styles.workspaceBold}>{item.issue || 'No Description'}</p>
                </div>
              ))}
            </div>
          </div>
          <SchedulerMenubar isGridLocked={isGridLocked} setIsGridLocked={setIsGridLocked} />
          <div className={styles.calendarContainer}>
            <SchedulerCalendar
              isGridLocked={isGridLocked}
              schedules={schedules}
              setSchedules={setSchedules}
              onScheduleMove={schedule => {
                // Remove from workspace when moved to calendar
                setWorkspaceItems(prev => prev.filter(item => item.id !== schedule.id));
              }}
              workers={workers}
            />
          </div>
        </div>
        <MiniTechProfileModal />
        <TimeBlockerModal />
        <SMSModal />
      </div>
    </ScheduleProvider>
  );
};

export default SchedulePage;
