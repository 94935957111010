import { useState, useEffect, useRef } from 'react';
import styles from '../scheduler.module.css';
import { useSchedule } from '../context/ScheduleContext';

const ActionsDropdown = ({ variant = 'split' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState('Sick Leave');
  const dropdownRef = useRef(null);
  const { openTimeBlockerModal } = useSchedule();

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  const handleTimeBlockerClick = () => {
    setIsOpen(false);
    openTimeBlockerModal();
  };

  const handleLeaveClick = leaveType => {
    setSelectedLeave(leaveType);
    setIsOpen(false);
  };

  return (
    <div className={styles.dropdownContainer} ref={dropdownRef}>
      {variant === 'split' ? (
        <button onClick={() => setIsOpen(!isOpen)} className={styles.dropdownButton}>
          <div className={styles.leftSection}>
            <span className={styles.actionText}>Actions</span>
          </div>
          <div className={styles.rightSection}>
            <img
              src="/images/icons/chevron-down.svg"
              alt="chevron"
              className={styles.chevronIcon}
            />
          </div>
        </button>
      ) : (
        <button onClick={() => setIsOpen(!isOpen)} className={styles.singleDropdownButton}>
          {selectedLeave}
          <img
            src="/images/scheduler/navigation-arrow.svg"
            alt="chevron"
            className={styles.chevronIcon}
          />
        </button>
      )}

      {isOpen && (
        <div className={styles.dropdownMenu} style={{ width: variant === 'single' && '100%' }}>
          {variant === 'split' ? (
            <>
              <button
                className={`${styles.dropdownItem} ${styles.withIcon}`}
                onClick={handleTimeBlockerClick}
              >
                <img src="/images/scheduler/time-blocker.svg" alt="time blocker" /> Time Blocker
              </button>
              <button className={`${styles.dropdownItem} ${styles.withIcon}`}>
                <img src="/images/scheduler/map.svg" alt="map" /> View Map
              </button>
              <button className={`${styles.dropdownItem} ${styles.withIcon}`}>
                <img src="/images/scheduler/map.svg" alt="map" /> Cash Account
              </button>
            </>
          ) : (
            <>
              <button
                className={styles.dropdownItem}
                onClick={() => handleLeaveClick('Sick Leave')}
              >
                Sick Leave
              </button>
              <button
                className={styles.dropdownItem}
                onClick={() => handleLeaveClick('Annual Leave')}
              >
                Annual Leave
              </button>
              <button
                className={styles.dropdownItem}
                onClick={() => handleLeaveClick('Personal Leave')}
              >
                Personal Leave
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ActionsDropdown;
