/* eslint-disable no-unused-vars */
import LeftNavOption from "../Settings/LeftNavOption";
import LeftNavTitle from "../Settings/LeftNavTitle";
import LeftNavOrganisations from "./LeftNavOrganisations";
import { useEffect, useState, useCallback } from "react";
import { WBButton, WBModal } from "../UIComponents/WBComponents";
import { PostDB } from "../DBTools";
import { useNavigate } from "react-router";
// import { useDebounce } from "../../lib/js/debounce";

const LeftNav = () => {
  const navigate = useNavigate();
  const [isAddOrgModalOpen, setIsAddOrgModalOpen] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [returnedID, setReturnedID] = useState(null);
  const [orgRefresh, setOrgRefresh] = useState(false);

  const [isLeftNavOpen, setIsLeftNavOpen] = useState(true);

  const [leftNavOrgs, setLeftNavOrgs] = useState();

  const toggleAddOrgModal = () => setIsAddOrgModalOpen(!isAddOrgModalOpen);
  const toggleLeftNav = () => setIsLeftNavOpen(!isLeftNavOpen);


  const BuildOrg = async (orgID) => {
    if (orgName.length > 0) {
      const response = await PostDB({
        branch: "/addOrg", json: {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ org_name: orgID })
        }
      });
      if (response.inserted_org_id !== null) {
        setReturnedID(response.inserted_org_id);
      }
    }
    else {
      alert("Please enter a valid name.")
    }
  }

  useEffect(() => {
    if (returnedID !== null) {
      alert("Organisation has been successfully added");
      toggleAddOrgModal();
      setOrgRefresh(!orgRefresh);
      return navigate("/settings/organisation/edit/" + returnedID);
    }
  }, [returnedID])

  useEffect(() => {
    setLeftNavOrgs(<LeftNavOrganisations />)
  }, [orgRefresh])

  return (
    <div id="leftnav">
      {isAddOrgModalOpen ? <WBModal title="Add New Organisation" closeFunction={toggleAddOrgModal} width={"20%"} customSubmit={<WBButton title="Create" color={"white"} func={async () => BuildOrg(orgName)} />}>
        <div className="popup-context">
          <h2>Name</h2>
          <h3 className="not-bold">Max 20 Characters</h3>
          <input type="text" maxLength={20} name="org_name" className="form-control" id="organisation_name" placeholder="Enter Organisation name" onChange={(myOrg) => setOrgName(myOrg.target.value)} autoFocus></input>
        </div>
      </WBModal> : ''}
      {/* {isAddOrgModalOpen ? <AddOrganisations closeAddOrgMethod={toggleAddOrgModal} /> : ''} */}
      {/* <nav id="leftNavBar" ref={scrollRef}
        onScroll={({ target }) => {
          setScrollPosition(target.scrollTop);
        }}> */}
      <nav id="leftNavBar">
        {/* <div className="whitespace" /> */}
        <div className="section">
          <div className="header">
            <h4>Organisations</h4>
            {/* <LeftNavAdd link={toggleAddOrgModal} /> */}
            <WBButton title="Add Organisation" func={toggleAddOrgModal} color={"white"} height="40px" />
          </div>
          {leftNavOrgs}
        </div>

        <div className="section">
          <div className="header">
            <h4>User Management</h4>
            {/* <LeftNavAdd>
              <LeftNavDropDownItem title="Add Office User" click={() => addOfficeUser} />
              <LeftNavDropDownItem title="Add Field User" click={() => addFieldUser} />
            </LeftNavAdd> */}
            <WBButton title="Add User" color={"white"} height="40px" />
            {/* <WBDropdown leftitem="+ Add" rightitem={<i className="icon-arrow-down filter-blue"></i>} position="center" color={"blueandwhitedropdown"}>
              <WBDropdownOption title="Add Office User" link={() => addOfficeUser} />
              <WBDropdownOption title="Add Field User" link={() => addFieldUser} />
            </WBDropdown> */}
          </div>

          <ul>
            <LeftNavOption
              name="Office Users"
              link="/settings/user_management/office_users"
            />
            <LeftNavOption
              name="Field Users"
              link="/settings/user_management/field_users"
            />
          </ul>
        </div>

        <div className="section">
          <LeftNavTitle name="Price Book" />
          <ul>
            <LeftNavOption name="Services" link="/settings/services" />
            <LeftNavOption name="Materials" link="/settings/materials" />
            <LeftNavOption name="Uilities" link="/settings/utilities" />
            <LeftNavOption name="Filters" link="/settings/filters" />
            {/* <LeftNavOption name="Sub Filters" link="/settings/subfilters" /> */}
            <LeftNavOption name="Tags" link="/settings/tags" />
            <LeftNavOption name="Spiffs" link="/settings/spiffs" />
            <LeftNavOption name="Industries" link="/settings/industries" />
            <LeftNavOption name="Categories" link="/settings/categories" />
            {/* <LeftNavOption
              name="Sub Categories"
              link="/settings/subcategories"
            /> */}

          </ul>
        </div>

        <div className="section">
          <LeftNavTitle name="Customer Type" />
          <ul>
            <LeftNavOption
              name="Customer Type"
              link="/settings/customer_types"
            />
            <LeftNavOption
              name="Payment Terms (temporarily removed)"
              link="/settings/payment_terms"
              disabled
            />
            {/* IMPORTANT this has been temporarily removed as there is no data. This may change in the future */}
          </ul>
        </div>

        <div className="section">
          <LeftNavTitle name="Pricing and Rates" />
          <ul>
            <LeftNavOption name="Billable Hourly Rates" link="/settings/billhours" />
            <LeftNavOption name="Discount Logic" link="/settings/discounts" />
          </ul>
        </div>

        <div className="section">
          <LeftNavTitle name="User Operational" />
          <ul>
            <LeftNavOption
              name="Service Types"
              link="/settings/service_types"
            />
            <LeftNavOption name="Job Types" link="/settings/job_types" />
            <LeftNavOption
              name="Service Call Fees"
              link="/settings/service_callout_fees"
            />
            <LeftNavOption
              name="Service Responses"
              link="/settings/service_response"
            />
          </ul>
        </div>

        <div className="section">
          <LeftNavTitle name="Advanced Settings" />
          <ul>
            <LeftNavOption name="Memberships" link="/settings/membership" />
            <LeftNavOption
              name="Material Multipliers"
              link="/settings/material_multipliers"
            />{" "}
            {/*Spelling? */}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default LeftNav;
