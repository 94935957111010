import { useEffect, useState } from "react";
import { WBButton, WBCollector } from "../../../../Components/UIComponents/WBComponents";
import { PostDB } from "../../../../Components/DBTools";

const EditFilter = ({ closeModalMethod, editedID }) => {
    const [filtID, setFiltID] = useState();
    const [filtName, setFiltName] = useState();
    const [industry, setIndustry] = useState();
    const [allSubFilters, setAllSubFilters] = useState();
    const [selectedSubFilters, setSelectedSubFilters] = useState();
    const [editedSubFilters, setEditedSubFilters] = useState();

    const [changesMade, setChangesMade] = useState(false);

    useEffect(() => {
        const GetFilter = async () => {
            const response = await PostDB({
                branch: "/getFilter",
                json: {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ id: editedID })
                }
            });
            if (response !== undefined && response !== null) {
                setFiltID(response[0].filter_id);
                setFiltName(response[0].filter_name);
                setIndustry(response[0].trade_id);
                setAllSubFilters(response[0].sub_filters ? JSON.parse(response[0].sub_filters) : []);
            }
        }

        GetFilter();
    }, []);

    useEffect(() => {
        if (allSubFilters !== undefined && allSubFilters !== null) {
            debugger;
            setSelectedSubFilters(allSubFilters.map((subFilter) => ({
                id: subFilter.sub_filter_id,
                name: subFilter.sub_filter_name
            })));
        }
    }, [allSubFilters]);

    const SaveFilter = async () => {
        let bodyObj = {
            filter_id: filtID,
            filter_name: filtName,
            trade_id: industry,
            sub_filters: editedSubFilters.map((subFilter) => ({
                sub_filter_id: subFilter.id,
                sub_filter_name: subFilter.name,
                status: 1
            })),
            description: "",
            active: 1
        }
        debugger;
        let response = await PostDB({
            branch: "/editFilter", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bodyObj)
            }
        });
        if (response.api_status === "success") {
            alert("Filter has been successfully added");
            closeModalMethod();
        }
        else {
            alert("There was an error saving this Utility. Check your fields and try again");
        }
    }

    const handleBGClick = (e) => {
        const background = document.querySelector(".bg-darkblue");
        if (background === e.target) {
            closeModalMethod();
        }
    }

    return (
        <div className="add-utility-page">
            <div className="bg-darkblue" onClick={(e) => handleBGClick(e)}>
                <div className="content">
                    <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Edit Filter</b></h4>
                        </div>
                        <div className="button-row right gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                        </div>
                    </div>
                    <div className="body">
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="filtName" className="form-label">
                                        Filter Name
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <input
                                        type="text"
                                        name="filtName"
                                        id="filtName"
                                        placeholder="enter filter name"
                                        className="half"
                                        defaultValue={filtName}
                                        onChange={(e) => { setChangesMade(true); setFiltName(e.target.value) }}
                                        autoFocus
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="hr" />
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="subFilters" className="form-label">
                                        Sub Filters
                                    </label>
                                    <WBCollector allCollectorItems={selectedSubFilters} selectedTitle="Sub Filter" returnFunc={(e) => { setChangesMade(true); setEditedSubFilters(e) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <WBButton title="Save" color={"green"} func={() => SaveFilter()} />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default EditFilter;