import { useEffect, useState } from "react";
import { WBDropdown, WBDropdownOption, useWBTableData } from "../../UIComponents/WBComponents";
import { CallDB } from "../../DBTools";

const SubCategoryItems = ({ editFunc, setEditedID }) => {

    const deleteUser = () => { }; // deleteUser()

    const [categories, setCategories] = useState();
    const [subCategoriesFormatted, formatSubCategories] = useState(<></>);

    const subcategories = useWBTableData();

    const GetCategories = async () => {
        let response = await CallDB({ branch: "/pricebook/categories" });
        // debugger;
        if (response !== undefined && response !== null) {
            setCategories(response);
        }
    }

    useEffect(() => {
        GetCategories();
    }, []);

    useEffect(() => {
        if (subcategories !== null && subcategories !== undefined) {
            if (subcategories.subcategories.length > 0) {
                formatSubCategories(
                    subcategories.subcategories.map((subcategory, index) => (
                        <div key={index} className="row" onDoubleClick={() => { setEditedID(subcategory.id); editFunc(); }}>
                            <div className="static1 cell">{subcategory.name}</div>
                            <div className="cell">{categories.find((category) => category.id === subcategory.category_id).name}</div>
                            <div className="cell">{subcategory.status === 1 ? "Active" : "Inactive"}</div>
                            <div className="cell auto">
                                <WBDropdown leftitem={<div className="title">
                                    <div className="option">
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                    </div>
                                </div>} align="end">
                                    <WBDropdownOption title="Edit" func={() => { setEditedID(subcategory.id); editFunc(); }} />
                                    <WBDropdownOption title="Delete" func={() => deleteUser(subcategory.id)} />
                                </WBDropdown>
                            </div>
                        </div>
                    ))
                )
            }
            else {
                formatSubCategories(<div>No items found</div>);
            }
        }
        else {
            formatSubCategories(<div>No items found</div>);
        }
    }, [categories, subcategories])

    return (
        <div className="body" id="bodyData">{subCategoriesFormatted}</div>
    );
}

export default SubCategoryItems;