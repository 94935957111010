import styles from '../PaymentModal.module.css';

const FormInput = ({title, placeholder, subtitle}) => {
  const {paymentBodyItem, paymentBodyItemTitle, paymentBodyItemInput, paymentBodyItemSubtitle} =
    styles;
  return (
    <div className={paymentBodyItem}>
      <p className={paymentBodyItemTitle}>
        {title}
        {subtitle && <span className={paymentBodyItemSubtitle}>{subtitle}</span>}
      </p>
      <input type="text" className="paymentBodyItemInput" placeholder={placeholder} />
    </div>
  );
};

export default FormInput;
