import {useLocation} from 'react-router-dom';
import {useState} from 'react';
import styles from './runreports.module.css';
import ReportsSidebar from './components/ReportsSidebar';
import HeaderSection from './components/HeaderSection';
import GraphSection from './components/GraphSection';
import FilterSection from './components/FilterSection';

const RunReportsPage = () => {
  const location = useLocation();
  const [searchValue, setSearchValue] = useState('');

  const filters = [
    {label: 'Amount', operator: '>=', value: '89.00'},
    {label: 'Job Status', operator: '=', value: 'Completed'},
    {label: 'Booked By', operator: '=', value: 'Jonnalyn'},
  ];

  return (
    <div className={styles.runReportsPageContainer}>
      <ReportsSidebar />
      <div className={styles.bodyContainer}>
        <HeaderSection />
        <GraphSection />
        <FilterSection
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          filters={filters}
        />
      </div>
    </div>
  );
};

export default RunReportsPage;
