import styles from './MessageBubble.module.css';

const MessageBubble = ({isReceived, children}) => (
  <div className={styles.messageBubble}>
    <span className={`${styles.sender} ${isReceived ? styles.receiverName : styles.senderName}`}>
      {isReceived ? 'Ralph' : 'You'}
    </span>
    <div className={`${styles.messageWrapper} ${isReceived ? styles.received : styles.sent}`}>
      <p className={styles.message}>{children}</p>
    </div>
  </div>
);

export default MessageBubble;
