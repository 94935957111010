import styles from './InfoPanel.module.css';
import {WBButton} from '../../../../../Components/UIComponents/WBComponents';

const InfoPanel = ({technician}) => {
  if (!technician) return null;

  return (
    <div className={styles.infoPanel}>
      <div className={styles.detailsGroup}>
        {/* Tech Info */}
        <div className={styles.techInfo}>
          <img
            src={technician.image}
            alt={`${technician.firstName} ${technician.lastName}`}
            className={styles.avatar}
          />
          <div className={styles.nameGroup}>
            <span className={styles.firstName}>{technician.firstName}</span>
            <span className={styles.lastName}>{technician.lastName}</span>
          </div>
        </div>

        {/* Registration & Phone */}
        <div className={styles.details}>
          <div className={styles.detailGroup}>
            <label className={styles.label}>Truck Registration</label>
            <span className={styles.value}>{technician.id}</span>
          </div>
          <div className={styles.detailGroup}>
            <label className={styles.label}>Phone</label>
            <span className={styles.value}>{technician.phone}</span>
          </div>
        </div>
      </div>

      {/* Action Buttons */}
      <div className={styles.actions}>
        <WBButton
          title="Truck Products"
          icon={<i className="icon-truck-products" />}
          color="white"
          func={() => {
            console.log('Truck Products');
          }}
          height="45px"
        />
        <WBButton
          title="Purchase Orders"
          color="white"
          func={() => {
            console.log('Purchase Orders');
          }}
          height="45px"
        />
      </div>
    </div>
  );
};

export default InfoPanel;
