import { createContext, useState } from "react";

export const JobCardModalContext = createContext();

export const JobCardModalProvider = ({ children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalData, setModalData] = useState(null);

  const openModal = (title, data = null) => {
    setIsModalVisible(true);
    setModalTitle(title);
    setModalData(data);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setModalTitle("");
    setModalData(null);
  };

  return (
    <JobCardModalContext.Provider
      value={{ 
        isModalVisible, 
        modalTitle,
        modalData,
        openModal,
        closeModal
      }}
    >
      {children}
    </JobCardModalContext.Provider>
  );
}; 