import {createContext, useState, useMemo} from 'react';

export const AccountingContext = createContext();

export const AccountingProvider = ({children}) => {
  // Initial table data
  const initialData = [
    {
      invoiceNumber: '548262',
      jobNumber: '658934',
      saleDate: '04-11-2024',
      customer: 'Bessie Cooper',
      amount: '$89.00',
      paid: '$89.00',
      receipt: '461366',
    },
    {
      invoiceNumber: '548263',
      jobNumber: '658935',
      saleDate: '04-11-2024',
      customer: 'Jenny Wilson',
      amount: '$125.50',
      paid: '$125.50',
      receipt: '461367',
    },
    {
      invoiceNumber: '548264',
      jobNumber: '658936',
      saleDate: '04-10-2024',
      customer: 'Robert Fox',
      amount: '$299.99',
      paid: '$299.99',
      receipt: '461368',
    },
    {
      invoiceNumber: '548265',
      jobNumber: '658937',
      saleDate: '04-10-2024',
      customer: 'Wade Warren',
      amount: '$75.25',
      paid: '$75.25',
      receipt: '461369',
    },
    {
      invoiceNumber: '548266',
      jobNumber: '658938',
      saleDate: '04-09-2024',
      customer: 'Esther Howard',
      amount: '$450.00',
      paid: '$450.00',
      receipt: '461370',
    },
    {
      invoiceNumber: '548267',
      jobNumber: '658939',
      saleDate: '04-09-2024',
      customer: 'Cameron Williamson',
      amount: '$199.99',
      paid: '$199.99',
      receipt: '461371',
    },
    {
      invoiceNumber: '548268',
      jobNumber: '658940',
      saleDate: '04-08-2024',
      customer: 'Brooklyn Simmons',
      amount: '$89.99',
      paid: '$89.99',
      receipt: '461372',
    },
    {
      invoiceNumber: '548269',
      jobNumber: '658941',
      saleDate: '04-08-2024',
      customer: 'Leslie Alexander',
      amount: '$149.50',
      paid: '$149.50',
      receipt: '461373',
    },
    {
      invoiceNumber: '548270',
      jobNumber: '658942',
      saleDate: '04-07-2024',
      customer: 'Ralph Edwards',
      amount: '$299.00',
      paid: '$299.00',
      receipt: '461374',
    },
    {
      invoiceNumber: '548271',
      jobNumber: '658943',
      saleDate: '04-07-2024',
      customer: 'Kristin Watson',
      amount: '$175.75',
      paid: '$175.75',
      receipt: '461375',
    },
    {
      invoiceNumber: '548272',
      jobNumber: '658944',
      saleDate: '04-06-2024',
      customer: 'Floyd Miles',
      amount: '$225.50',
      paid: '$225.50',
      receipt: '461376',
    },
    {
      invoiceNumber: '548273',
      jobNumber: '658945',
      saleDate: '04-06-2024',
      customer: 'Jerome Bell',
      amount: '$399.99',
      paid: '$399.99',
      receipt: '461377',
    },
    {
      invoiceNumber: '548274',
      jobNumber: '658946',
      saleDate: '04-05-2024',
      customer: 'Marvin McKinney',
      amount: '$159.99',
      paid: '$159.99',
      receipt: '461378',
    },
    {
      invoiceNumber: '548275',
      jobNumber: '658947',
      saleDate: '04-05-2024',
      customer: 'Darrell Steward',
      amount: '$275.00',
      paid: '$275.00',
      receipt: '461379',
    },
    {
      invoiceNumber: '548276',
      jobNumber: '658948',
      saleDate: '04-04-2024',
      customer: 'Savannah Nguyen',
      amount: '$129.99',
      paid: '$129.99',
      receipt: '461380',
    },
    {
      invoiceNumber: '548277',
      jobNumber: '658949',
      saleDate: '04-04-2024',
      customer: 'Guy Hawkins',
      amount: '$199.50',
      paid: '$199.50',
      receipt: '461381',
    },
  ];

  const [tableData] = useState(initialData);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  // Memoized filtered data based on search query
  const filteredData = useMemo(() => {
    if (!searchQuery) return tableData;

    return tableData.filter(item => {
      const searchTerm = searchQuery.toLowerCase();
      return (
        item.invoiceNumber.toLowerCase().includes(searchTerm) ||
        item.jobNumber.toLowerCase().includes(searchTerm) ||
        item.customer.toLowerCase().includes(searchTerm)
      );
    });
  }, [tableData, searchQuery]);

  // Memoized paginated data
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return filteredData.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  }, [filteredData, currentPage]);

  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

  const handleSearch = query => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  return (
    <AccountingContext.Provider
      value={{
        tableData,
        filteredData: paginatedData, 
        searchQuery,
        handleSearch,
        currentPage,
        totalPages,
        handlePageChange,
      }}
    >
      {children}
    </AccountingContext.Provider>
  );
};
