import styles from './AccountingFilters.module.css';
import {
  WBDropdown,
  WBDropdownOption,
} from '../../../../Components/UIComponents/WBComponents';
import { useState } from 'react';

const AccountingFilters = () => {
  // State management similar to FilterSection
  const [selectedOrg, setSelectedOrg] = useState('All Organisations');
  const [selectedTrade, setSelectedTrade] = useState('All Trades');
  const [selectedLocation, setSelectedLocation] = useState('All');

  // Define options
  const orgOptions = ['All Organisations', 'Organisation 1', 'Organisation 2'];
  const tradeOptions = ['All Trades', 'Trade 1', 'Trade 2'];
  const locationOptions = ['All', 'Location 1', 'Location 2'];

  const downArrow = <i className="icon-arrow-down"></i>;

  return (
    <div className={styles.filtersContainer}>
      <div className={styles.filterGroup}>
        <label>Organisation</label>
        <WBDropdown
          leftitem={selectedOrg}
          rightitem={downArrow}
          color="white"
          width="fit-content"
        >
          {orgOptions.map(option => (
            <WBDropdownOption 
              key={option}
              title={option}
              func={() => setSelectedOrg(option)}
            />
          ))}
        </WBDropdown>
      </div>

      <div className={styles.filterGroup}>
        <label>Trades</label>
        <WBDropdown 
          leftitem={selectedTrade}
          rightitem={downArrow}
          color="white"
          width="fit-content"
        >
          {tradeOptions.map(option => (
            <WBDropdownOption
              key={option}
              title={option}
              func={() => setSelectedTrade(option)}
            />
          ))}
        </WBDropdown>
      </div>

      <div className={styles.filterGroup}>
        <label>Locations</label>
        <WBDropdown 
          leftitem={selectedLocation}
          rightitem={downArrow}
          color="white"
          width="fit-content"
        >
          {locationOptions.map(option => (
            <WBDropdownOption
              key={option}
              title={option}
              func={() => setSelectedLocation(option)}
            />
          ))}
        </WBDropdown>
      </div>
    </div>
  );
};

export default AccountingFilters;
