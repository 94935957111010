import {WBDropdown, WBDropdownOption} from '../../../JobCard/common/WBDropdown';
import styles from '../PaymentModal.module.css';

const CustomDropdown = ({ title, leftitem, leftItemIcon }) => {
  const {paymentBodyItem, paymentBodyItemTitle, iconArrowDown} = styles;
  const arrowDown = <i className={iconArrowDown}></i>;
  return (
    <div className={paymentBodyItem}>
      <p className={paymentBodyItemTitle}>{title}</p>
      <WBDropdown
        leftitem={leftitem}
        leftItemIcon={leftItemIcon}
        rightitem={arrowDown}
        color="whiteandwhite"
        height="40px"
        width="331px"
        borderRadius="10px"
        leftItemPadding="11px 0 11px 20px"
        style={{
          fontSize: '16px',
          border: '1px solid rgba(240, 240, 240, 1)',
          color: 'rgba(53, 57, 61, 1)',
        }}
        dropdownMenuStyle={{
          top: 'calc(100% + 4px)',
          minWidth: 'calc(100% + 8px)',
          borderRadius: '10px',
          border: '1px solid #F3FAFF',
          transformOrigin: 'top right',
          transform: 'translateX(8px)',
          overflow: 'hidden',
        }}
      />
    </div>
  );
};

export default CustomDropdown;
