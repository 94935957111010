import {InventoryProvider, useInventory} from './context/InventoryContext';
import DashboardPage from './Dashboard/DashboardPage';
import TrucksPage from './Trucks/TrucksPage';
import ReportsPage from './Reports/ReportsPage';
import SettingsPage from './Settings/SettingsPage';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import {WBButton} from '../../Components/UIComponents/WBComponents';
import styles from './InventoryPage.module.css';

const InventoryContent = () => {
  const {activeTab, setActiveTab} = useInventory();

  const getBreadcrumbItems = () => {
    return ['Inventory', activeTab.charAt(0).toUpperCase() + activeTab.slice(1)];
  };

  const handleTabChange = tab => {
    setActiveTab(tab);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'dashboard':
        return <DashboardPage />;
      case 'trucks':
        return <TrucksPage />;
      case 'reports':
        return <ReportsPage />;
      case 'settings':
        return <SettingsPage />;
      default:
        return <DashboardPage />;
    }
  };

  return (
    <div className={styles.inventoryPage}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <h1>Inventory</h1>
          <Breadcrumbs items={getBreadcrumbItems()} />
        </div>
        <div className={styles.headerButtons}>
          <WBButton
            title="Dashboard"
            color={activeTab === 'dashboard' ? 'darknavy' : 'white'}
            func={() => handleTabChange('dashboard')}
          />
          <WBButton
            title="Trucks"
            color={activeTab === 'trucks' ? 'darknavy' : 'white'}
            func={() => handleTabChange('trucks')}
          />
          <WBButton
            title="Reports"
            color={activeTab === 'reports' ? 'darknavy' : 'white'}
            func={() => handleTabChange('reports')}
          />
          <WBButton
            title="Settings"
            color={activeTab === 'settings' ? 'darknavy' : 'white'}
            icon={
              <i className={`icon-settings-${activeTab === 'settings' ? 'light' : 'primary'}`} />
            }
            func={() => handleTabChange('settings')}
          />
        </div>
      </div>
      {renderContent()}
    </div>
  );
};

const InventoryPage = () => {
  return (
    <InventoryProvider>
      <InventoryContent />
    </InventoryProvider>
  );
};

export default InventoryPage;
