import { Link } from "react-router-dom";
import { WBButton, WBLoader } from "../Components/UIComponents/WBComponents";
import { CallURL } from "../Components/DBTools";
import { toast } from "react-toastify";
import { useState } from "react";

const Login = () => {
    const [userInputUsername, setUserInputUsername] = useState("");
    const [userInputPassword, setUserInputPassword] = useState("");
    const [isLoading, setisLoading] = useState(false);

    const CheckLogin = async () => {
        setisLoading(true)
        let URL = `${process.env.REACT_APP_NODEJSAPI}/login`;
        const response = await CallURL({
            url: URL,
            json: {
                method: 'POST', // Ensure this matches the method in your API
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: userInputUsername,
                    password: userInputPassword
                }) // Include password in the request body
            }
        });

        if (response.ok) {
            const data = await response.json(); // Parse the response JSON
            if (data.success === true) {
                localStorage.setItem("sessiontoken", data.token);
                window.location = "/";
            } else {
                // alert("The credentials you entered are incorrect. Please try again.");
                toast("The credentials you entered are incorrect. Please try again.");
                setisLoading(false)
            }
        } else {
            // alert("There was a problem logging you in. Please try again later.");
            toast("There was a problem logging you in. Please try again later.");
            setisLoading(false)
        }
    };

    return (
        <div className="flex">
            <div className="login">
                <div className="loginmodal">

                    <div id="loginHeader">
                        <img src="/images/LoginLogo.svg" alt="loginlogo" />
                    </div>

                    <div id="loginContent">
                        <div className="input">
                            <label htmlFor="username" className="form-label">Username</label>
                            <input id="username" type="username" className={"form-control" + (isLoading ? " disabled" : "")} placeholder={`user@example.com`} onChange={(e) => setUserInputUsername(e.target.value)} />
                        </div>

                        <div className="input">
                            <label htmlFor="password" className="form-label">Password</label>
                            <div>
                                <input id="password" type="password" className={"form-control" + (isLoading ? " disabled" : "")} placeholder={`************`} onChange={(e) => setUserInputPassword(e.target.value)} />
                                <WBButton title={"Forgot Password?"} color={"transparent"} cssClass={"forgotpassword"} func={() => toast("Pretend there is a fancy password reset page here")} />
                            </div>
                        </div>

                        <div className="checkbox" >
                            <input type="checkbox" id="checkbox" /> <label htmlFor="checkbox" className="form-label">Remember Password</label>
                        </div>

                        <WBButton title={isLoading ? <WBLoader filter="filter-black" /> : "Login"} func={() => { !isLoading && CheckLogin() }} cssClass={"loginbutton"} />

                        <div className="input" style={{ marginTop: "40px" }}>
                            <p className="signup">Don't have account? Please <a href="/" onClick={(e) => { e.preventDefault(); window.location = "/signup"; }}>Sign Up</a></p>
                        </div>

                        {/* <div className="hr" />
                        <div className="input googlesignin">
                            <p>...or alternatively sign in with your Google account</p>
                            <WBButton title={"Sign in with Google"} icon={<i className="icon-google" ></i>} color={"grey"} func={() => alert("This feature is not yet implemented. Please login above.")} />
                        </div> */}
                    </div>
                </div>
                <div className="input tos" id="loginFooter">
                    <p>Registering to this website, you accept out <Link onClick={() => alert("Pretend this goes to the Terms of use page")}>Terms of use</Link> and our <Link onClick={() => alert("Pretend this goes to the Privacy Policy page")}>Privacy Policy</Link></p>

                    <img src="/images/mini-logo.svg" width={40} />
                </div>
            </div>
        </div>
    )
}

export default Login;