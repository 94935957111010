import { useState } from "react";
import { WBButton } from "../../../../Components/UIComponents/WBComponents";
import { PostDB } from "../../../../Components/DBTools";

const EditIndustry = ({ closeModalMethod, editedID }) => {

    const [changesMade, setChangesMade] = useState(false);
    const [industryName, setIndustryName] = useState("");

    const Save = async () => {
        const response = await PostDB({
            branch: "/editIndustry", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: editedID, name: industryName })
            }
        });
        if (response.api_status === 'success') {
            alert('Industry has been successfully added');
            closeModalMethod();
        } else {
            alert('There was an error saving this Industry. Check your fields and try again');
        }
    };

    return (
        <div className="add-utility-page">
            <div className="bg-darkblue">
                <div className="content">
                    <div className="border-bottom bg-white p-20 gap-10" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Edit Industry</b></h4>
                        </div>
                        <div className="button-row right gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                        </div>
                    </div>
                    <div className="border-bottom bg-white h-full gap-10">
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="code" className="form-label">
                                        Industry Name
                                    </label>
                                    <input
                                        type="text"
                                        name="code"
                                        className="half"
                                        id="code"
                                        placeholder="enter industry name"
                                        onChange={e => { setChangesMade(true); setIndustryName(e.target.value); }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <p>Note: This will update the industry name for every Category associated to this Industry. All services who use this Industry will have their Industry name updated to reflect this change.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <WBButton title="Save" color={"green"} func={() => Save()} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditIndustry;