import styles from './ReportsPage.module.css';

const ReportsPage = () => {
  return (
    <div className={styles.reportsPage}>
      <h2>Reports Dashboard</h2>
    </div>
  );
};

export default ReportsPage;
