import { useEffect, useState } from "react";
import { PostDB } from "../../../../Components/DBTools";
import { WBButton, WBDropdown, WBDropdownOption, WBIOSToggle } from "../../../../Components/UIComponents/WBComponents";

const AddSubFilter = ({ closeModalMethod }) => {
    const [filtID, setFiltID] = useState();
    const [filtName, setFiltName] = useState();
    const [subFiltName, setSubFiltName] = useState();
    const [filtDesc, setFiltDesc] = useState();
    const [allFilters, setAllFilters] = useState();
    const [allFiltersFormatted, setAllFiltersFormatted] = useState();

    const [changesMade, setChangesMade] = useState(false);

    useEffect(() => {
        const GetAllFilters = async () => {

            const response2 = await PostDB({
                branch: "/getFiltersNoPage", json: {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            });
            if (response2 !== undefined && response2 !== null) {
                setAllFilters(response2.filters);
            }
        }

        GetAllFilters();
    }, [])


    const SaveSubFilter = async () => {

        let bodyObj = {
            filter_id: filtID,
            sub_filter_id: null,
            sub_filter_name: subFiltName,
            active: 1
        }
        let response = await PostDB({
            branch: "/editSubFilter", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bodyObj)
            }
        });
        if (response.api_status === "success") {
            alert("Filter has been successfully added");
            closeModalMethod();
        }
        else {
            alert("There was an error saving this Utility. Check your fields and try again");
        }
    }

    const UpdateExistingFilter = (filter) => {
        setFiltID(filter.filter_id);
        setFiltName(filter.name);
        setFiltDesc(filter.description);
        // console.log("filter: ", filter);
    }

    useEffect(() => {
        if (allFilters !== undefined && allFilters !== null)
            setAllFiltersFormatted(
                allFilters.map(filter => {
                    return <WBDropdownOption key={filter.filter_id} value={filter.filter_id} title={filter.name} func={() => UpdateExistingFilter(filter)} />
                })
            );
    }, [allFilters])

    const handleBGClick = (e) => {
        const background = document.querySelector(".bg-darkblue");
        if (background === e.target) {
            closeModalMethod();
        }
    }

    return (
        <div className="add-utility-page">
            <div className="bg-darkblue" onClick={(e) => handleBGClick(e)}>
                <div className="content">
                    <div className="border-bottom bg-white p-20 gap-10" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Add Sub Filter</b></h4>
                        </div>
                        <div className="button-row right gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                        </div>
                    </div>
                    <div className="border-bottom bg-white h-full gap-10">
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="filterDetails">Filter Details:</label>
                                    <div id="filterDetails" className="border-grey border-radius-10">
                                        <div className="row">
                                            <div className="col">
                                                <div className="input">
                                                    <label htmlFor="code" className="mb-0 form-label">ID:</label>
                                                    <p className="pl-10"> {filtID ? filtID : <i>No parent filter</i>}</p>
                                                </div>
                                                <div className="input">
                                                    <label htmlFor="filtName" className="form-label mb-0">
                                                        Filter Name
                                                    </label>
                                                    <p className="pl-10">{filtName ? filtName : <i>No parent filter name</i>}</p>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="input">
                                                    <label htmlFor="code" className="mb-0 form-label">Selected Filter:</label>
                                                    <WBDropdown leftitem={filtName} rightitem={<i className="icon-arrow-down"></i>} defaultValue="Select Filter" color="white" >
                                                        {allFiltersFormatted}
                                                    </WBDropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="input">
                                                    <label htmlFor="filtDesc" className="mb-0 form-label">
                                                        Filter Description
                                                    </label>
                                                    <p className="pl-10">{filtDesc ? filtDesc : <i>No parent filter description</i>}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="filtName" className="form-label">
                                        Sub Filter Name
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <input
                                        type="text"
                                        name="filtName"
                                        className="half"
                                        id="filtName"
                                        placeholder="enter filter name"
                                        defaultValue={subFiltName}
                                        onChange={(e) => { setChangesMade(true); setSubFiltName(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-bottom bg-white p-20 gap-10 bottom-sticky">
                        <WBButton title="Save" color={"green"} func={() => SaveSubFilter()} />
                    </div>
                </div >
            </div >
        </div >
    )
}

export default AddSubFilter;