import { useEffect, useState } from 'react';
import styles from './scheduler.module.css';

const JobList = ({ title, jobs }) => {


  if (jobs.length === 0) return;
  console.log("Jobs:", jobs, jobs.description);

  return (

    <div className={styles.jobListContainer}>
      <p>{title}</p>
      <div className={styles.jobList}>
        {
          jobs.map((job, index) => (
            <div
              key={index}
              className={styles.jobItem}
              draggable="true"
              onDragStart={e => {
                e.dataTransfer.setData(
                  'application/json',
                  JSON.stringify({
                    ...job,
                    type: 'newJob',
                  })
                );
              }}
            >
              <div className={styles.jobLine}></div>

              <div className={styles.jobDetailsWrapper}>
                <div className={styles.jobDetailsContainer}>

                  <div className={styles.jobSideDetails}>
                    <div className={styles.jobLocation}>
                      {job.location}
                      <span className={styles.jobTime}>{job.time}</span>
                    </div>
                    <div className={styles.jobTags}>{job && job.description.split(",").map(type => <span className={styles.tag}>{type}</span>)}</div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>

  )



}
// FIX: get jobs from DB and update the list
const SchedulerSidebar = () => {


  // const unassignedJobs = [
  //   {location: 'Homebush', description: 'Leaking Tap / Toilet', time: '15 mins ago' },
  //   {location: 'Other', description: 'Plumbing', time: '15 mins ago' },
  //   {location: 'Manly', description: 'Leaking Tap / Toilet', time: '15 mins ago' },
  //   {location: 'Hornsby', description: 'Hot Water Service', time: '15 mins ago' },
  // ];

  const [unassignedJobs, setunassignedJobs] = useState([]);


  useEffect(() => {
    const fetchUnassignedJobs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_NODEJSAPI}/jobs/getAllJobs`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          }
        }).then(data => data.json());

        const updatedResponse = response.map(job => {

          const time = new Date(job.time);
          return {
            ...job,
            time: timeSince(time)
          }
        })

        setunassignedJobs(updatedResponse);
      } catch (error) {
        console.log("Error fetching jobs:", error);
      }
    }

    fetchUnassignedJobs();

  }, [])

  function timeSince(date) {
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);

    if (seconds < 60) return `${seconds} seconds ago`;
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) return `${minutes} minutes ago`;
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours} hours ago`;
    const days = Math.floor(hours / 24);
    return `${days} days ago`;
  }

  // Example Usage
  const pastDate = new Date("2024-02-09T12:00:00"); // Example past date
  console.log(timeSince(pastDate));


  const ongoingJobs = [
    { location: 'Homebush', description: 'Leaking Tap / Toilet', time: '15 mins ago' },
    { location: 'Other', description: 'Plumbing', time: '15 mins ago' },
    { location: 'Manly', description: 'Leaking Tap / Toilet', time: '15 mins ago' },
    { location: 'Hornsby', description: 'Hot Water Service', time: '15 mins ago' },
  ];

  const reminders = [
    { location: 'Homebush', description: 'Leaking Tap / Toilet', time: '15 mins ago' },
    { location: 'Other', description: 'Plumbing', time: '15 mins ago' },
    { location: 'Manly', description: 'Leaking Tap / Toilet', time: '15 mins ago' },
    { location: 'Hornsby', description: 'Hot Water Service', time: '15 mins ago' },
  ];

  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.sidebarContainerInner}>
        <div className={styles.sideBarItem}>
          <div className={styles.sidebarHeader}>
            <p>Unassigned Jobs</p>
            <img src="/images/scheduler/arrow-up.svg" alt="arrow-up" />
          </div>
          <JobList title="Plumbing" jobs={unassignedJobs} />
        </div>
        <div className={styles.sideBarItem}>
          <div className={`${styles.sidebarHeader} ${styles.sidebarHeaderTop}`}>
            <p>Ongoing Jobs</p>
            <img src="/images/scheduler/arrow-up.svg" alt="arrow-up" />
          </div>
          <JobList title="Plumbing" jobs={ongoingJobs} />
        </div>
        <div className={styles.sideBarItem}>
          <div className={`${styles.sidebarHeader} ${styles.sidebarHeaderTop}`}>
            <div className={styles.remindersFlex}>
              <img src="/images/scheduler/bell.svg" alt="bell" />
              <p>Reminders</p>
            </div>
            <img src="/images/scheduler/arrow-up.svg" alt="arrow-up" />
          </div>
          <JobList title="Plumbing" jobs={reminders} />
        </div>
      </div>
    </div>
  );
};

export default SchedulerSidebar;
