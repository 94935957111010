import { useState, useContext, useEffect } from "react";
import { JobBookingContext } from "../JobBookingContext";
import { PostDB } from "../../../../Components/DBTools";

export const useFetchJobCustomerLists = () => {
  const { setFetchedData, fetchedData } = useContext(JobBookingContext);
  const response = fetchedData ? true : false;

  useEffect(() => {
    getIndustriesAndJobTypes();
    getCustomerTypes();
    getPaymentTerms();
    getCustomers();
    getJobPriority();
    getContactsRelationship();
    getCalloutFees();
    getServiceTypes();
    getCancellationReasons();
    getCategoryTypes();
    getJobSpecialBookings();

  }, []);

  useEffect(() => {
    console.log("fetchedData", fetchedData);
  }, [fetchedData]);
  const fetchData = async (branch, method = "POST") => {
    try {
      return await PostDB({
        branch,
        json: {
          method,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      });
    } catch (error) {
      console.error(`Error fetching ${branch}:`, error);
      return [];
    }
  };

  const fetchAndSetData = async (endpoint, key, method = "POST") => {
    const response = await fetchData(endpoint, method);
    if (Array.isArray(response)) {
      setFetchedData((prevData) => ({
        ...prevData,
        [key]: response,
      }));
    }
  };

  const getCustomerTypes = () =>
    fetchAndSetData("/settings/customer_types", "customerTypesList");
  const getPaymentTerms = () =>
    fetchAndSetData("/settings/payment_terms", "paymentTermsList");
  const getCustomers = () =>
    fetchAndSetData("/getcustomers", "customersList");
  const getJobPriority = () =>
    fetchAndSetData("/settings/job_priority", "jobPriorityList");
  const getContactsRelationship = () =>
    fetchAndSetData(
      "/settings/contacts_relationship",
      "contactsRelationshipList"
    );
  const getCalloutFees = () =>
    fetchAndSetData("/settings/callout_fees", "callOutFeesList");
  const getServiceTypes = () =>
    fetchAndSetData("/settings/service_types", "serviceTypesList");
  const getCategoryTypes = () => {
    fetchAndSetData("/getJobCategories", "categoryTypesList");
  }
  const getJobSpecialBookings = () => {
    fetchAndSetData("/getJobSpecialBookings", "specialBookingsList");
  }
  const getCancellationReasons = () =>
    fetchAndSetData("/job/cancel_reasons", "cancellationReasonsList", "GET");

  const getIndustriesAndJobTypes = async () => {
    const industriesResponse = await fetchData("/settings/industries");
    const jobTypesResponse = await fetchData("/settings/job_types");

    if (Array.isArray(industriesResponse) && Array.isArray(jobTypesResponse)) {
      const industriesWithJobTypes = industriesResponse.map((industry) => ({
        ...industry,
        jobTypes: jobTypesResponse
          .filter((jobType) => jobType.trade_id === industry.id)
          .map(({ id, title }) => ({ id, title })),
      }));

      setFetchedData((prevData) => ({
        ...prevData,
        industriesList: industriesWithJobTypes,
      }));
    } else {
      console.error("Invalid response format for industries or job types");
    }
  };

  return {
    response,
  };
};
