import styles from '../runreports.module.css';
import { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const GraphControls = () => {
  const graphTypes = ['line', 'pie', 'bar'];
  return (
    <div className={styles.graphTitleRight}>
      {graphTypes.map(type => (
        <img 
          key={type}
          src={`/images/reports/${type}graph.svg`} 
          alt={`${type}graph`}
        />
      ))}
    </div>
  );
};

const GraphSection = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');

    const data = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
        {
          label: 'Current',
          data: [356000, 350000, 340000, 350000, 345000, 340000, 330000, 280000, 320000, 350000, 345000, 335000],
          borderColor: '#0094FF',
          tension: 0.4,
          fill: false
        },
        {
          label: 'Compare Last Year',
          data: [340000, 330000, 320000, 330000, 325000, 320000, 310000, 260000, 300000, 330000, 325000, 315000],
          borderColor: '#E5E5E5',
          tension: 0.4,
          fill: false
        }
      ]
    };

    chartInstance.current = new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
            align: 'end',
            labels: {
              usePointStyle: true,
              pointStyle: 'circle',
              padding: 20,
              boxWidth: 8,
              boxHeight: 8,
              color: '#171717',
              font: {
                family: 'robotoRegular',
                size: 16
              },
              generateLabels: (chart) => {
                const datasets = chart.data.datasets;
                return datasets.map((dataset, i) => ({
                  text: dataset.label,
                  fillStyle: dataset.borderColor,
                  strokeStyle: dataset.borderColor,
                  lineWidth: 0,
                  hidden: !chart.isDatasetVisible(i),
                  index: i
                }));
              }
            }
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false
            },
            title: {
              display: true,
              text: 'Amount($)',
              font: {
                family: 'robotoBold',
                size: 16
              },
              color: '#171717'
            },
            min: 0,
            max: 400000,
            ticks: {
              callback: (value) => {
                return value >= 1000 ? `${value/1000}k` : value;
              },
              values: [0, 120000, 200000, 356000],
              font: {
                family: 'robotoRegular',
                size: 16
              },
              color: '#171717'
            }
          },
          x: {
            grid: {
              display: false
            },
            ticks: {
              color: '#171717',
              font: {
                family: 'robotoRegular',
                size: 16
              }
            }
          }
        }
      }
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  return (
    <div className={styles.graphContainer}>
      <div className={styles.graphTitleContainer}>
        <p className={styles.reportsTitle}>Number of Sales</p>
        <GraphControls />
      </div>
      <div style={{ height: '400px', width: '100%', marginTop: '20px' }}>
        <canvas ref={chartRef}></canvas>
      </div>
    </div>
  );
};

export default GraphSection;
