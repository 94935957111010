import { useState } from "react";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddSubCategories from "./Add/AddSubCategories";
import EditSubCategories from "./Edit/EditSubCategories";
import SubCategoryItems from "../../../Components/Settings/Categories/SubCategoryItems";

const SubCategoriesPage = () => {

    const [isModalAddOpen, setIsModalAddOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);

    const toggleAddModal = () => { setIsModalAddOpen(!isModalAddOpen); } // addService()
    const toggleEditModal = () => { setIsModalEditOpen(!isModalEditOpen); };

    const [editedID, setEditedID] = useState();


    const headers = ["sub category name", "category name", "status", "action"];

    return (
        <div className="content-area-full">
            {isModalAddOpen && <AddSubCategories closeModalMethod={toggleAddModal} />}
            {isModalEditOpen && <EditSubCategories closeModalMethod={toggleEditModal} editedID={editedID} />}

            <div className="flex floating-box flex-column bg-white p-20 gap-10">
                <div className="flex justify-between">
                    <div className="text-base">
                        <b>SubCategories</b>
                    </div>
                    <WBButton title="Add Sub Category" color="white" func={toggleAddModal} />
                </div>
                <WBTable tableData="/subCategoriesJson" tableHeaders={headers} >
                    <SubCategoryItems editFunc={toggleEditModal} setEditedID={setEditedID} />
                </WBTable>
            </div>
        </div>
    );
};

export default SubCategoriesPage;
