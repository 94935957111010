import styles from './StatusBadge.module.css';

const StatusBadge = ({status, variant = 'rectangular', value, className}) => {
  if (!status && !value) return null;

  const getStatusStyles = () => {
    if (status?.startsWith('APU')) {
      return styles.apuBadge;
    }

    switch (status?.toLowerCase()) {
      case 'low stock':
        return styles.lowStock;
      case 'restock required':
        return styles.restockRequired;
      case 'awaiting':
        return styles.awaiting;
      default:
        return styles.default;
    }
  };

  const getValueStyles = () => {
    return parseInt(value) <= 2 ? styles.criticalValue : styles.warningValue;
  };

  if (variant === 'circular' && value) {
    return (
      <div
        className={`${styles.circle} ${getValueStyles()} ${className || ''}`}
        role="status"
        aria-label={`Quantity: ${value}`}
      >
        {value}
      </div>
    );
  }

  return (
    <span
      className={`${styles.badge} ${getStatusStyles()} ${className || ''}`}
      role="status"
      aria-label={`Status: ${status}`}
    >
      {status}
    </span>
  );
};

export default StatusBadge;
