import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AllRoutes from "./lib/js/routes";
import { UseTech } from "./lib/js/usetech";
import { useEffect, useState } from "react";
import { OrganisationContext } from "./lib/contexts/OrganisationContext";
import Login from "./Pages/Login";
import { CustomAlerts } from "././Components/UIComponents/CustomAlerts";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { JobModalProvider } from './lib/contexts/JobModalContext';
import GlobalJobModals from './Components/GlobalJobModals';

function App() {
  const [selectedOrg, setSelectedOrg] = useState(localStorage.getItem("selectedOrg") || '1');
  UseTech();
  CustomAlerts();

  const ValidateUser = () => {
    const validUser = localStorage.getItem("sessiontoken") !== null;
    if (validUser) {
      return true;
    }
    else if (window.location.pathname === "/login") {
      return false;
    }
    window.location = "/login";
    return false;
  }

  useEffect(() => {
    if (selectedOrg) {
      localStorage.setItem("selectedOrg", selectedOrg);
      // console.log("Updated selectedOrg in localStorage:", selectedOrg);
    }
  }, [selectedOrg]);


  return (
    <JobModalProvider>
      <div className="App">
        <OrganisationContext.Provider value={selectedOrg}>
          <BrowserRouter>
            {ValidateUser() ? (
              <AllRoutes changeOrg={(e) => setSelectedOrg(e)} />
            ) : (
              <Login />
            )}
          </BrowserRouter>
        </OrganisationContext.Provider>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          closeOnClick
          pauseOnHover
          toastClassName="custom-toast"
          bodyClassName="custom-toast-body"
        />
        <GlobalJobModals />
      </div>
    </JobModalProvider>
  );

}
// const AppContent = ({ setSelectedOrg }) => {
//   const { settings, toggleSettings, rebalanceSettings } = useSettingsContext();
//   const isSettings = localStorage.getItem("isSettings") === "true";
//   console.log("settings: " + settings + " | isSettings: " + isSettings);
//   const location = useLocation();
//   
//   // if (isSettings && location !== '*') {
//   //   if (!settings) {
//   //     rebalanceSettings();
//   //   }
//   //   return (
//   //     <div>
//   //       <WBSettingsNav toggleSettings={toggleSettings} />
//   //       <div style={{ height: "70px" }}></div>
//   //       <SettingsRoutes />
//   //     </div>
//   //   )
//   // } else if (settings) {
//   //   rebalanceSettings();
//   //   toggleSettings();
//   //   return (
//   //     <div>
//   //       <NavBar changeOrg={setSelectedOrg} toggleSettings={toggleSettings} />
//   //       {/* <div style={{ height: "70px" }}></div> */}
//   //       <AllRoutes gotosettings={toggleSettings} />
//   //     </div>
//   //   )
//   // } else {
//   //   return (
//   //     <div>
//   //       <NavBar changeOrg={setSelectedOrg} toggleSettings={toggleSettings} />
//   //       {/* <div style={{ height: "70px" }}></div> */}
//   //       <AllRoutes gotosettings={toggleSettings} />
//   //     </div>
//   //   )
//   // }
//   <
// };


export default App;

// z-index notes:
// 104 -> modal x button
// 103 -> alert content
// 102 -> alert popups
// 101 -> modal header/footer
// 100 -> dropdown menus
// 99 -> modals
// 98 -> modal background
// 97 -> navigation
// 96 -> left navigation (settings)