import styles from './DashboardPage.module.css';
import {WBButton} from '../../../Components/UIComponents/WBComponents';
import InventoryTable from './Components/InventoryTable/InventoryTable';
import TruckOrdersTable from './Components/TruckOrdersTable/TruckOrdersTable';

const DashboardPage = () => {
  return (
    <div className={styles.dashboardPage}>
      <div className={styles.chartSection}>
        {/* Placeholder for chart */}
        <div className={styles.chartPlaceholder}>
          <div className={styles.chartHeader}>
            <div className={styles.chartTitle}>
              <i className="icon-coin"></i>
              <h2>Jobs Cost vs Materials Cost (Monthly)</h2>
            </div>
            <div className={styles.dateRange}>
              <span>Date Range</span>
              <WBButton title="01-10-2024 - 31-10-2024" color="white" />
            </div>
          </div>
          <div className={styles.chart}>{/* Placeholder for chart */}</div>
        </div>
      </div>

      <div className={styles.tablesSection}>
        <TruckOrdersTable />
        <InventoryTable />
      </div>
    </div>
  );
};

export default DashboardPage;
