import React, { useState } from "react";
import styles from "../scheduler.module.css";
import { useSchedule } from '../context/ScheduleContext';

const SchedulerDropdown = ({ icon = null, alt, text = null, onClick }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { openTimeBlockerModal } = useSchedule();

    const handleTimeBlockerClick = () => {
        setIsOpen(false);
        openTimeBlockerModal();
    };

    return (
        <div className={`${styles.dropdownContainer}`} onClick={() => setIsOpen(!isOpen)}>
            <div className={styles.dropdownActions}>
                <span>{text}</span>
            </div>
            <div className={styles.dropdownArrow}>
                <img src="/images/scheduler/dropdown.svg" alt="dropdown" />
            </div>
            {isOpen && (
                <div className={styles.dropdownContent}>
                    <div className={styles.dropdownItem} onClick={handleTimeBlockerClick}>
                        <img src="/images/scheduler/time-blocker.svg" alt="timeblocker" />
                        <p className={styles.dropdownText}>Time Blocker</p>
                    </div>
                    <div className={styles.dropdownItem} onClick={onClick}>
                        <img src="/images/scheduler/view-map.svg" alt="viewmap" />
                        <p className={styles.dropdownText}>View Map</p>
                    </div>
                    <div className={styles.dropdownItem} onClick={onClick}>
                        <img src="/images/scheduler/cash-account.svg" alt="cashaccount" />
                        <p className={styles.dropdownText}>Cash Account</p>
                    </div>
                </div>
            )}
        </div>
    );
};

const SchedulerButton = ({ icon = null, alt, text = null, onClick, dropdown = false }) => {
    const buttonStyles = text !== null
        ? `${styles.schedulerButtonWide} ${styles.schedulerButtonText}`
        : styles.schedulerButton;

    return dropdown ? (
        <SchedulerDropdown
            icon={icon}
            alt={alt}
            text={text}
            onClick={onClick}
        />
    ) : (
        <div className={buttonStyles} onClick={onClick}>
            {text == null && icon != null && <img src={icon} alt={alt} />}
            {text && icon == null && <span>{text}</span>}
            {text && icon && (
                <div className={styles.iconTextContainer}>
                    <img src={icon} alt={alt} />
                    <span>{text}</span>
                </div>
            )}
        </div>
    );
};

export default SchedulerButton;
