import styles from './TechniciansList.module.css';
import StatusBadge from '../../../../../Components/StatusBadge/StatusBadge';

const TechniciansList = ({data, selectedTechnician, onSelectTechnician}) => {
  return (
    <div className={styles.listContainer}>
      {Object.entries(data).map(([category, technicians]) => (
        <div key={category} className={styles.category}>
          <h2 className={styles.categoryTitle}>{category}</h2>
          <div className={styles.techniciansList}>
            {technicians.map(tech => (
              <div
                key={tech.id}
                className={`${styles.technicianItem} ${
                  selectedTechnician?.id === tech.id ? styles.active : ''
                }`}
                onClick={() => onSelectTechnician(tech)}
                tabIndex={0}
                role="button"
                aria-label={`Select ${tech.firstName} ${tech.lastName}`}
              >
                <div className={styles.techInfo}>
                  <img src={tech.image} alt={`${tech.firstName} ${tech.lastName}`} className={styles.avatar} />
                  <div className={styles.details}>
                    <div className={styles.name}>{`${tech.firstName} ${tech.lastName}`}</div>
                    <div className={styles.id}>{tech.id}</div>
                  </div>
                </div>
                <div className={styles.statusContainer}>
                  {tech.status && <StatusBadge status={tech.status} />}
                  {tech.badge && <StatusBadge status={tech.badge} />}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TechniciansList;
