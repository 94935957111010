import styles from './InventoryTable.module.css';

const InventoryTable = () => {
  const materials = [
    {
      title: 'Doritos',
      used: 2,
      price: 4.0,
      totalPrice: 8.0,
    },
    {
      title: 'Nutella',
      used: 1,
      price: 8.0,
      totalPrice: 8.0,
    },
    // Add more sample data as needed
  ];

  const totalAmount = materials.reduce((sum, item) => sum + item.totalPrice, 0);

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableHeader}>
        <h2>Top 10 Materials Used</h2>
      </div>

      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Title</th>
              <th></th>
              <th>Used</th>
              <th>Price</th>
              <th>Total Price</th>
            </tr>
          </thead>
          <tbody>
            {materials.map((material, index) => (
              <tr key={index}>
                <td>{material.title}</td>
                <td></td>
                <td>{material.used}</td>
                <td>${material.price.toFixed(2)}</td>
                <td>${material.totalPrice.toFixed(2)}</td>
              </tr>
            ))}
            <tr className={styles.totalRow}>
              <td colSpan={4} className={styles.totalLabel}>
                Total Amount
              </td>
              <td>${totalAmount.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InventoryTable;
