import styles from './SettingsPage.module.css';

const SettingsPage = () => {
  return (
    <div className={styles.settingsPage}>
      <h2>Settings Configuration</h2>
    </div>
  );
};

export default SettingsPage;
