import styles from '../runreports.module.css';

const PageButton = ({icon, text}) => {
  return (
    <div className={styles.pageButton}>
      {icon && <img src={`/images/reports/${icon}.svg`} alt={icon} />}
      <p>{text}</p>
    </div>
  );
};

export default PageButton;
