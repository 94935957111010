import styles from '../clientHeader.module.css';
import {useState, useEffect} from 'react';
import JobPropertyDetailsModal from './JobPropertyDetailsModal';
import JobJobDetailsModal from './JobJobDetailsModal';
import JobSummaryModal from './JobSummaryModal';
import JobCustomerDetailsModal from './JobCustomerDetailsModal';
import { useJobModal } from '../../../lib/contexts/JobModalContext';

const Client = ({data}) => {
  const { toggleModal } = useJobModal();

  return (
    <section>
      <div className={styles.leftSection}>
        <div className={styles.customerName}>
          {data?.first_name} {data?.last_name}
        </div>
        <div className={styles.tags}>
          <div className={styles.vip}>
            <span className={styles.vipText}>VIP</span>
          </div>
          <div>
            <span className={styles.financeApproved}>Finance Approved</span>
          </div>
          <div className={styles.google}>
            <img src="/images/google-icon.svg" alt="Google" />
            <span>Google</span>
          </div>
        </div>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.jobButton} onClick={() => toggleModal('addNewJobCustomer')}>
          <a>Add New Job</a>
        </div>
        <div>
          <a href="#" className={styles.actionsButton}>
            <span className={styles.text}>Actions</span>
            <span className={styles.arrow}>
              <img src="/images/icons/chevron-down.svg" alt="Down Arrow" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Client;
