import styles from './SearchBox.module.css';

const SearchBox = ({placeholder = 'Search...', onSearch, className}) => {
  const handleSearchChange = e => {
    onSearch(e.target.value);
  };

  return (
    <div className={`${styles.searchWrapper} ${className || ''}`}>
      <span className={styles.searchIcon}>
        <i className="icon-search" />
      </span>
      <input
        type="text"
        placeholder={placeholder}
        onChange={handleSearchChange}
        aria-label={placeholder}
      />
    </div>
  );
};

export default SearchBox;
