import HeaderButton from './HeaderButton';
import styles from '../runreports.module.css';

const SaveReportModal = ({isOpen, onClose}) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <p>Save Report</p>
          <span className={styles.closeBtn} onClick={onClose}>
            &times;
          </span>
        </div>
        <div className={styles.modalBody}>
          <div className={styles.inputGroup}>
            <p>Name Report</p>
            <input type="text" placeholder="Enter report name" className={styles.modalInput} />
          </div>
        </div>
        <div className={styles.modalFooter}>
          <div className={styles.cancelButton} onClick={onClose}>
            Cancel
          </div>
          <HeaderButton icon="check" text="Save Report" />
        </div>
      </div>
    </div>
  );
};

export default SaveReportModal;
