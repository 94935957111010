export const ToBase64 = (url) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(url);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = () => {
            reject("There was an error converting the image to base64. Please try again or use a different file type.");
        };
    });
};

export const BlobToBase64 = async (blobUrl) => {
    try {
        const response = await fetch(blobUrl);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = () => {
                reject("There was an error converting the blob to base64. Please try again or use a different file type.");
            };
        });
    } catch (error) {
        throw new Error("There was an error converting the blob to base64. Please try again or use a different file type.");
    }
};


export const Base64ToURL = (base64string) => {
    const splitIndex = base64string.indexOf(',');
    const contentType = base64string.slice(0, splitIndex);
    const base64 = base64string.slice(splitIndex + 1);

    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: contentType });
    const url = URL.createObjectURL(blob);

    return url;
    // return url.split('blob:')[1];
}