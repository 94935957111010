import {createContext, useContext, useState} from 'react';

const ScheduleContext = createContext();

export const ScheduleProvider = ({children}) => {
  const [techProfileModal, setTechProfileModal] = useState({
    isOpen: false,
    techData: null,
    selectedTechId: null,
    position: null,
  });

  const [timeBlockerModal, setTimeBlockerModal] = useState(false);
  const [selectedTechnicians, setSelectedTechnicians] = useState(new Set());
  const [smsModal, setSmsModal] = useState({
    isOpen: false,
    techData: null,
    position: null,
  });

  const [selectedChat, setSelectedChat] = useState({
    id: 1,
    name: 'Ralph Edwards',
    role: 'Plumber',
    avatar: '/images/scheduler/iconModal.svg',
  });

  const [chatList, setChatList] = useState([
    {
      id: 1,
      name: 'Ralph Edwards',
      role: 'Plumber',
      unread: true,
      avatar: '/images/scheduler/iconModal.svg',
    },
    {
      id: 2,
      name: 'John Doe',
      role: 'Electrician',
      unread: true,
      avatar: '/images/scheduler/smsModalStatic/avatar2.svg',
    },
    {
      id: 3,
      name: 'Jane Smith',
      role: 'Carpenter',
      unread: true,
      avatar: '/images/scheduler/smsModalStatic/avatar3.svg',
    },
    {
      id: 4,
      name: 'Michael Johnson',
      role: 'HVAC Tech',
      unread: false,
      avatar: '/images/scheduler/smsModalStatic/avatar4.svg',
    },
    {
      id: 5,
      name: 'Sarah Wilson',
      role: 'Painter',
      unread: true,
      avatar: '/images/scheduler/smsModalStatic/avatar5.svg',
    },
    {
      id: 6,
      name: 'David Miller',
      role: 'Roofer',
      unread: false,
      avatar: '/images/scheduler/smsModalStatic/avatar6.svg',
    },
    {
      id: 7,
      name: 'Emily Brown',
      role: 'Landscaper',
      unread: true,
      avatar: '/images/scheduler/smsModalStatic/avatar7.svg',
    },
  ]);

  const [messages] = useState([
    {
      id: 1,
      content:
        "Hey Ralph, can you please arrange to pick up the water tank from 91 Marigold Street, Revesby when you're available? Let me know if you need any details. Thanks!",
      timestamp: '2024-03-20T10:00:00',
      isReceived: false,
    },
    {
      id: 2,
      content:
        "Hi Eddy, sure, I'll arrange to pick it up. Can you confirm the size of the tank and if there's anything specific I should know for transport? Thanks!",
      timestamp: '2024-03-20T10:05:00',
      isReceived: true,
    },
    {
      id: 3,
      content:
        "Hi Ralph, thanks! The tank is 500 liters, 1.5 meters tall, and you'll need trolley or straps for securing it. Let me know if you need anything else. Appreciate",
      timestamp: '2024-03-20T10:10:00',
      isReceived: false,
    },
  ]);

  const [messageList, setMessageList] = useState(messages);

  const openTechProfileModal = (techData, techId, clickEvent) => {
    const rect = clickEvent.currentTarget.getBoundingClientRect();
    setTechProfileModal({
      isOpen: true,
      techData,
      selectedTechId: techId,
      position: {
        top: rect.top + window.scrollY,
        left: rect.right + 20,
      },
    });
  };

  const closeTechProfileModal = () => {
    setTechProfileModal({
      isOpen: false,
      techData: null,
      selectedTechId: null,
      position: null,
    });
  };

  const openTimeBlockerModal = () => setTimeBlockerModal(true);
  const closeTimeBlockerModal = () => {
    setTimeBlockerModal(false);
    setSelectedTechnicians(new Set());
  };

  const handleTechnicianSelect = techId => {
    setSelectedTechnicians(prev => {
      const newSet = new Set(prev);
      if (newSet.has(techId)) {
        newSet.delete(techId);
      } else {
        newSet.add(techId);
      }
      return newSet;
    });
  };

  const handleSelectAllTechnicians = technicians => {
    setSelectedTechnicians(prev => {
      if (prev.size === technicians.length) {
        return new Set();
      }
      return new Set(technicians.map(tech => tech.id));
    });
  };

  const openSmsModal = (techData, position) => {
    setTechProfileModal(prev => ({
      ...prev,
      isOpen: false,
    }));

    setSmsModal({
      isOpen: true,
      techData,
      position,
    });
  };

  const closeSmsModal = () => {
    setSmsModal({
      isOpen: false,
      techData: null,
      position: null,
    });

    if (techProfileModal.techData) {
      setTechProfileModal(prev => ({
        ...prev,
        isOpen: true,
      }));
    } else {
      setTechProfileModal({
        isOpen: false,
        techData: null,
        selectedTechId: null,
        position: null,
      });
    }
  };

  const handleChatSelect = chatId => {
    const selected = chatList.find(chat => chat.id === chatId);
    if (selected) {
      setSelectedChat({
        id: selected.id,
        name: selected.name,
        role: selected.role,
        avatar: selected.avatar,
      });

      setChatList(prevList =>
        prevList.map(chat => (chat.id === chatId ? {...chat, unread: false} : chat))
      );
    }
  };

  const sendMessage = content => {
    const newMessage = {
      id: messageList.length + 1,
      content,
      timestamp: new Date().toISOString(),
      isReceived: false,
    };

    setMessageList(prev => [...prev, newMessage]);
  };

  return (
    <ScheduleContext.Provider
      value={{
        techProfileModal,
        openTechProfileModal,
        closeTechProfileModal,
        timeBlockerModal,
        openTimeBlockerModal,
        closeTimeBlockerModal,
        selectedTechnicians,
        handleTechnicianSelect,
        handleSelectAllTechnicians,
        smsModal,
        openSmsModal,
        closeSmsModal,
        chatList,
        selectedChat,
        handleChatSelect,
        messageList,
        sendMessage,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};

export const useSchedule = () => {
  const context = useContext(ScheduleContext);
  if (!context) {
    throw new Error('useSchedule must be used within a ScheduleProvider');
  }
  return context;
};
