import styles from '../runreports.module.css';

const HeaderButton = ({icon, text, onClick = null}) => {
  return (
    <div className={styles.headerButton} onClick={onClick}>
      {icon && <img src={`/images/reports/${icon}.svg`} alt={icon} />}
      <p>{text}</p>
    </div>
  );
};

export default HeaderButton;
