import {Link} from 'react-router-dom';

const SubNav = ({activeSubNav, onSubNavClick}) => {
  const subNavItems = [
    {title: 'Accounting', path: '/accounting'},
    {title: 'Purchase Orders', path: '/purchase-orders'},
    {title: 'Technicians', path: '/technicians'},
    {title: 'Inventory', path: '/inventory'},
  ];

  return (
    <nav className="subnav">
      <ul>
        {subNavItems.map((item) => {
          const isActive = activeSubNav === item.path;
          return (
            <li
              key={item.path}
              className={isActive ? 'active' : ''}
            >
              <Link
                to={item.path}
                onClick={() => onSubNavClick(item.path)}
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  fontFamily: isActive ? 'robotoBold' : 'robotoRegular',
                  display: 'block',
                  width: '100%',
                  height: '100%',
                  padding: '5px 15px',
                  cursor: 'pointer'
                }}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default SubNav;
