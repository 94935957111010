import styles from '../runreports.module.css';
import HeaderButton from './HeaderButton';
import PageButton from './PageButton';

const data = [
  {
    jobNumber: '658934',
    invoiceNumber: '548262',
    amount: '89.00',
    paid: '89.00',
    technician: 'Alim Jashari',
    state: 'VIC',
    department: 'HVAC',
    saleDate: '04-11-2024',
    calloutFee: '89.00',
    jobStatus: 'Completed',
    bookedBy: 'Jonnalyn',
  },
  {
    jobNumber: '658935',
    invoiceNumber: '548263',
    amount: '145.50',
    paid: '145.50',
    technician: 'John Smith',
    state: 'NSW',
    department: 'Plumbing',
    saleDate: '04-11-2024',
    calloutFee: '95.00',
    jobStatus: 'Completed',
    bookedBy: 'Michael',
  },
  {
    jobNumber: '658936',
    invoiceNumber: '548264',
    amount: '220.00',
    paid: '0.00',
    technician: 'Sarah Wilson',
    state: 'QLD',
    department: 'Electrical',
    saleDate: '04-11-2024',
    calloutFee: '85.00',
    jobStatus: 'Pending',
    bookedBy: 'Jonnalyn',
  },
  {
    jobNumber: '658937',
    invoiceNumber: '548265',
    amount: '175.25',
    paid: '175.25',
    technician: 'David Chen',
    state: 'VIC',
    department: 'HVAC',
    saleDate: '04-11-2024',
    calloutFee: '89.00',
    jobStatus: 'Completed',
    bookedBy: 'Michael',
  },
  {
    jobNumber: '658938',
    invoiceNumber: '548266',
    amount: '95.00',
    paid: '95.00',
    technician: 'Alim Jashari',
    state: 'NSW',
    department: 'HVAC',
    saleDate: '04-11-2024',
    calloutFee: '95.00',
    jobStatus: 'Completed',
    bookedBy: 'Jonnalyn',
  },
  {
    jobNumber: '658939',
    invoiceNumber: '548267',
    amount: '350.75',
    paid: '0.00',
    technician: 'Emma Brown',
    state: 'VIC',
    department: 'Plumbing',
    saleDate: '04-11-2024',
    calloutFee: '89.00',
    jobStatus: 'In Progress',
    bookedBy: 'Michael',
  },
  {
    jobNumber: '658940',
    invoiceNumber: '548268',
    amount: '180.00',
    paid: '180.00',
    technician: 'James Wilson',
    state: 'QLD',
    department: 'Electrical',
    saleDate: '04-11-2024',
    calloutFee: '85.00',
    jobStatus: 'Completed',
    bookedBy: 'Jonnalyn',
  },
  {
    jobNumber: '658941',
    invoiceNumber: '548269',
    amount: '125.50',
    paid: '125.50',
    technician: 'Sarah Wilson',
    state: 'VIC',
    department: 'HVAC',
    saleDate: '04-11-2024',
    calloutFee: '89.00',
    jobStatus: 'Completed',
    bookedBy: 'Michael',
  },
  {
    jobNumber: '658942',
    invoiceNumber: '548270',
    amount: '275.00',
    paid: '150.00',
    technician: 'David Chen',
    state: 'NSW',
    department: 'Plumbing',
    saleDate: '04-11-2024',
    calloutFee: '95.00',
    jobStatus: 'Partial Payment',
    bookedBy: 'Jonnalyn',
  },
  {
    jobNumber: '658943',
    invoiceNumber: '548271',
    amount: '195.25',
    paid: '195.25',
    technician: 'Emma Brown',
    state: 'VIC',
    department: 'Electrical',
    saleDate: '04-11-2024',
    calloutFee: '89.00',
    jobStatus: 'Completed',
    bookedBy: 'Michael',
  },
];

const FilterItem = ({label, operator, value}) => (
  <div className={styles.filterItem}>
    <p className={styles.filterBold}>{label}</p>
    <p>&nbsp;{operator}&nbsp;</p>
    <p>{value}</p>
  </div>
);

const ReportsTable = ({data}) => {
  return (
    <table className={styles.reportsTable}>
      <thead>
        <tr>
          <th>Job Number</th>
          <th>Invoice Number</th>
          <th>Amount</th>
          <th>Paid</th>
          <th>Technician</th>
          <th>State</th>
          <th>Department</th>
          <th>Sale Date</th>
          <th>Callout Fee</th>
          <th>Job Status</th>
          <th>Booked By</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td>{row.jobNumber}</td>
            <td>{row.invoiceNumber}</td>
            <td>${row.amount}</td>
            <td>${row.paid}</td>
            <td>{row.technician}</td>
            <td>{row.state}</td>
            <td>{row.department}</td>
            <td>{row.saleDate}</td>
            <td>${row.calloutFee}</td>
            <td>{row.jobStatus}</td>
            <td>{row.bookedBy}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const FilterSection = ({searchValue, setSearchValue, filters}) => {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableHeader}>
        <div className={styles.searchContainer}>
          <img src="/images/reports/search.svg" alt="search" />
          <input
            type="text"
            className={styles.searchInput}
            placeholder="Start typing (job id, customer details, etc.)"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
        </div>
        <HeaderButton icon="filter" text="Filters" />
      </div>
      <div className={styles.filterContainer}>
        {filters.map((filter, index) => (
          <FilterItem key={index} {...filter} />
        ))}
        <div className={styles.filterItem}>
          <p>+</p>
        </div>
      </div>

      <ReportsTable data={data} />

      <div className={styles.pageNumberContainer}>
        <PageButton text="1" />
        <PageButton text=">" />
        <PageButton text="<" />
        <PageButton text="3" />
        <PageButton text="4" />
        <PageButton text="..." />
        <PageButton text="..." />
        <PageButton text="43" />
      </div>
    </div>
  );
};

export default FilterSection;
