import styles from './AccountingPage.module.css';
import AccountingFilters from './Components/AccountingFilters/AccountingFilters';
import AccountingSummary from './Components/AccountingSummary/AccountingSummary';
import AccountingTable from './Components/AccountingTable/AccountingTable';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import {WBButton} from '../../Components/UIComponents/WBComponents';
import {AccountingProvider, AccountingContext} from './context/AccountingContext';
import {useContext} from 'react';
import { JobCardModalProvider } from '../../Components/Customer/context/JobCardModalContext';
import JobCardModal from '../../Components/Customer/JobCard/JobCardModal/JobCardModal';

const AccountingContent = () => {
  const breadcrumbItems = ['Settings', 'Accounting'];
  const {tableData} = useContext(AccountingContext);

  return (
    <div className={styles.accountingPage}>
      <div className={styles.wrapper}>
        <div className={styles.breadcrumbs}>
          <Breadcrumbs items={breadcrumbItems} />
        </div>

        <div className={styles.header}>
          <h1>Accounting</h1>
          <div className={styles.headerButtons}>
            <WBButton title="Reload Data" color="white" />
            <WBButton title="History" color="white" />
          </div>
        </div>

        <div className={styles.accountingControls}>
          <AccountingFilters />
          <AccountingSummary />
        </div>

        <div className={styles.invoicesHeader}>
          <h2 className={styles.invoicesTitle}>Invoices not pushed</h2> &nbsp;
          <span className={styles.invoiceCount}>({tableData.length})</span>
        </div>

        <AccountingTable />
      </div>
      <JobCardModal />
    </div>
  );
};

const AccountingPage = () => {
  return (
    <AccountingProvider>
      <JobCardModalProvider>
        <AccountingContent />
      </JobCardModalProvider>
    </AccountingProvider>
  );
};

export default AccountingPage;
