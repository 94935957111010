import {useState} from 'react';
import HeaderButton from './HeaderButton';
import SaveReportModal from './SaveReportModal';
import styles from '../runreports.module.css';

const HeaderSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.headerLeft}>
          <p className={styles.boldSidebar}>Date Range</p>
          <HeaderButton icon="calendar" text="01-10-2024 - 31-10-2024" />
        </div>
        <div className={styles.headerRight}>
          <HeaderButton icon="email" text="Email" />
          <HeaderButton icon="export" text="Export" />
          <HeaderButton icon="check" text="Save Report" onClick={() => setIsModalOpen(true)} />
        </div>
      </div>

      <SaveReportModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

export default HeaderSection;
