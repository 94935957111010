const ChangelogPage = () => {
  return (
    <div className="changelog">
      <div className="section">
        <h3>Log Changes</h3>
        <div className="log_section section">
          <div className="hr" />
          <div>
            <b>Thursday 6th of February, 2025</b>
          </div>
          <div>
            <p><b>Organisation</b></p>
            <p> - Fixed Add Organisation Modal</p>
            <p> - Added organisation image upload</p>
            <p> - Added text prompts for Service Locations for if there is no service locations</p>
            <p> - Added text prompts for Contractor Licence Numberrs if there is no contract licences</p>
            <p> - Adjusted height of BPAY Drawer</p>
            <p> - Fixed active status of BPAY </p>
            <p></p>
          </div>
          <div>
            <p><b>Services</b></p>
            <p> - Updated Service ID's to reflect original Schedular</p>
            <p> - Added filters default to "None" when selected</p>
            <p> - When creating similar service, displays created ID as popup alert</p>
            <p></p>
          </div>
          <div>
            <p><b>Materials</b></p>
            <p> - Fixed word wrap for Material title and description</p>
            <p></p>
          </div>
          <div>
            <p><b>Filters</b></p>
            <p> - Removed sub filters page. Implemented sub filters inside of Filters page</p>
            <p> - Adjusted design based on Figma</p>
            <p>    - Created new component for subfilters (WBCollector)</p>
            <p> - Can now add and edit sub filters inside of filters</p>
            <p> - Removed shortcut to subfilters page (page no longer required)</p>
          </div>
          <div>
            <p><b>Global Changes</b></p>
            <p> - Changed design of Input fields across Wurkbox.</p>
            <p> - Adjusted font size from 16px to 14px</p>
            <p> - Added new icons and svg files</p>
            <p> - General fixes and adjustments to custom components</p>
            <p> - General fixes and adjustments to css components</p>
          </div>
        </div>
        <div className="hr" />

      </div>
    </div>
  );
};

export default ChangelogPage;
