import {useState} from 'react';
import styles from './AccountingSummary.module.css';

const AccountingSummary = () => {
  const [selectedCard, setSelectedCard] = useState(0); // Default to first card

  const summaryData = [
    {
      amount: '$35,449.63',
      description: 'Invoices Not Pushed 27',
    },
    {
      amount: '$34,794.61',
      description: 'Payments Not Pushed 27',
    },
    {
      amount: '$8,189.00',
      description: 'Awaiting manual check',
    },
    {
      amount: '$314,952.42',
      description: 'Awaiting Payments',
    },
  ];

  const handleCardClick = index => {
    setSelectedCard(index);
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      setSelectedCard(index);
    }
  };

  return (
    <div className={styles.summaryContainer}>
      {summaryData.map((item, index) => (
        <div
          key={index}
          className={`${styles.summaryCard} ${selectedCard === index ? styles.active : ''}`}
          onClick={() => handleCardClick(index)}
          onKeyDown={e => handleKeyDown(e, index)}
          role="button"
          tabIndex={0}
          aria-label={`${item.amount} ${item.description}`}
          aria-pressed={selectedCard === index}
        >
          <h3>{item.amount}</h3>
          <p>{item.description}</p>
        </div>
      ))}
    </div>
  );
};

export default AccountingSummary;
