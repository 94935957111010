import React, { createContext, useContext, useState } from 'react';

const JobModalContext = createContext();

export const JobModalProvider = ({ children }) => {
  const [modals, setModals] = useState({
    addNewJobCustomer: false,
    addNewJobProperty: false,
    addNewJobJob: false,
    addNewJobSummary: false,
  });

  const closeAllModals = () => {
    setModals({
      addNewJobCustomer: false,
      addNewJobProperty: false,
      addNewJobJob: false,
      addNewJobSummary: false,
    });
  };

  const toggleModal = modalName => {
    closeAllModals();
    setModals(prevModals => ({
      ...prevModals,
      [modalName]: true,
    }));
  };

  return (
    <JobModalContext.Provider value={{ modals, toggleModal, closeAllModals }}>
      {children}
    </JobModalContext.Provider>
  );
};

export const useJobModal = () => {
  const context = useContext(JobModalContext);
  if (!context) {
    throw new Error('useJobModal must be used within a JobModalProvider');
  }
  return context;
}; 