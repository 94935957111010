import styles from './PaymentModal.module.css';
import {WBDropdown, WBDropdownOption} from '../../JobCard/common/WBDropdown';
import {WBButton} from '../../../UIComponents/WBComponents';
import {useJobCard} from '../../context/JobCardContext';
import FormInput from './components/FormInput';
import CustomDropdown from './components/CustomDropdown';

const PaymentModal = () => {
  const {
    closeButton,
    iconPlus,
    iconArrowDown,
    iconCalendar,
    iconFolder,
    iconGreenCheck,
    paymentModalOverlay,
    paymentModal,
    paymentModalHeader,
    paymentModalTitle,
    paymentModalBody,
    paymentModalBodyTitle,
    paymentModalBodyContent,
    paymentBodyLeft,
    paymentBodyItem,
    paymentBodyItemTitle,
    paymentBodyRight,
    paymentBodyItemSubtitle,
    paymentUploadContainer,
    paymentUploadTitle,
    paymentModalFooter,
  } = styles;
  const {closeModal} = useJobCard();

  const arrowDown = <i className={iconArrowDown}></i>;
  const calendar = <i className={iconCalendar}></i>;
  const folder = <i className={iconFolder}></i>;
  const greenCheck = <i className={iconGreenCheck}></i>;

  return (
    <div className={paymentModalOverlay}>
      <div className={paymentModal}>
        <div className={paymentModalHeader}>
          <div className={paymentModalTitle}>Add Payment</div>
          <button className={closeButton} onClick={closeModal}>
            <i className={iconPlus}></i>
          </button>
        </div>
        <div className={paymentModalBody}>
          <p className={paymentModalBodyTitle}>Payment Details</p>
          <div className={paymentModalBodyContent}>
            <div className={paymentBodyLeft}>
              <FormInput 
                title="Description"
                placeholder="Enter Description"
              />
              <CustomDropdown 
                title="Date"
                leftitem="Mon 23th December 2014"
                leftItemIcon={calendar}
              />
              <CustomDropdown 
                title="Payment Type"
                leftitem="Select Option"
              />
              <CustomDropdown 
                title="Collected By"
                leftitem="Select Option"
              />
            </div>
            <div className={paymentBodyRight}>
              <div className={paymentBodyItem} style={{marginTop: '9px'}}>
                <p className={paymentBodyItemTitle}>
                  Receipt
                  <span className={paymentBodyItemSubtitle}>
                    Please upload receipt for the payment
                  </span>
                </p>
                <div className={paymentUploadContainer}>
                  <p className={paymentUploadTitle}>
                    Supported formats <span>JPG, JPEG, PNG</span>
                  </p>
                  <WBButton
                    title="Upload Receipt"
                    color="blue"
                    width="252px"
                    height="45px"
                    icon={folder}
                    noborder={true}
                    styles={{
                      fontSize: '14px',
                      fontWeight: '700',
                      fontFamily: 'Arial',
                    }}
                  />
                </div>
              </div>
              <FormInput 
                title="Amount"
                placeholder="Enter Amount"
              />
              <FormInput 
                title="Terminal Id"
                placeholder="Enter Terminal Id"
              />
            </div>
          </div>
        </div>
        <div className={paymentModalFooter}>
          <WBButton title="Cancel" color="white" width="90px" height="44px" func={closeModal} />
          <WBButton
            title="Add Payment"
            color="white"
            width="151px"
            height="44px"
            icon={greenCheck}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
