import {useState, useMemo} from 'react';
import styles from './TrucksPage.module.css';
import SearchBox from '../../../Components/SearchBox/SearchBox';
import TechniciansList from './Components/TechniciansList/TechniciansList';
import InfoPanel from './Components/InfoPanel/InfoPanel';
import {techniciansData} from './data/techniciansData';

const TrucksPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTechnician, setSelectedTechnician] = useState(() => {
    const firstCategory = Object.values(techniciansData)[0];
    return firstCategory[0];
  });

  const filteredTechnicians = useMemo(() => {
    if (!searchQuery) return techniciansData;

    const filtered = {};
    Object.entries(techniciansData).forEach(([category, techs]) => {
      const filteredTechs = techs.filter(
        tech =>
          tech.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          tech.id.toLowerCase().includes(searchQuery.toLowerCase())
      );
      if (filteredTechs.length > 0) {
        filtered[category] = filteredTechs;
      }
    });
    return filtered;
  }, [searchQuery]);

  const handleSearch = query => {
    setSearchQuery(query);
  };

  const handleSelectTechnician = technician => {
    setSelectedTechnician(technician);
  };

  return (
    <div className={styles.trucksPage}>
      {/* Left Panel */}
      <div className={styles.leftPanel}>
        <SearchBox placeholder="Search technician, truck" onSearch={handleSearch} />
        <TechniciansList
          data={filteredTechnicians}
          selectedTechnician={selectedTechnician}
          onSelectTechnician={handleSelectTechnician}
        />
      </div>

      {/* Right Section */}
      <div className={styles.rightSection}>
        <InfoPanel technician={selectedTechnician} />
        <div className={styles.stockPanel}>{/* Content will go here */}</div>
      </div>
    </div>
  );
};

export default TrucksPage;
