import {useState, useEffect, useRef} from 'react';
import {useSchedule} from '../../context/ScheduleContext';
import {WBButton} from '../../../../Components/UIComponents/WBComponents';
import MessageBubble from './MessageBubble';
import styles from './SMSModal.module.css';

const SMSModal = () => {
  const {
    smsModal,
    closeSmsModal,
    chatList,
    selectedChat,
    handleChatSelect,
    messageList,
    sendMessage,
  } = useSchedule();
  const {isOpen, position} = smsModal;
  const [message, setMessage] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);

  const messageContainerRef = useRef(null);

  useEffect(() => {
    if (isOpen && messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [isOpen, selectedChat.id, messageList]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      handleChatSelect(1);
    }
  };

  const handleClose = e => {
    if (e) {
      e.stopPropagation();
    }

    if (isExpanded) {
      setIsExpanded(false);
      return;
    }

    setMessage('');
    closeSmsModal();
  };

  if (!isOpen || !position) return null;

  // Calculate modal position
  const modalHeight = 680;
  const windowHeight = window.innerHeight;

  let top = Math.max(
    20,
    Math.min(
      (windowHeight - modalHeight) / 2 + (position.top - windowHeight / 2) * 0.2,
      windowHeight - modalHeight - 20
    )
  );

  const handleSubmit = () => {
    if (message.trim()) {
      sendMessage(message.trim());
      setMessage('');
    }
  };

  return (
    <div className={styles.modalOverlay} onClick={handleClose}>
      <div
        className={`${styles.modalContainer} ${isExpanded ? styles.expanded : ''}`}
        style={{
          top: `${top}px`,
          left: `${position.left}px`,
        }}
        onClick={e => e.stopPropagation()}
      >
        {/* Simple Header for Collapsed State */}
        {!isExpanded && (
          <div className={styles.modalHeader}>
            <div className={styles.profileHeader}>
              <div className={styles.profileImageContainer}>
                <img
                  src="/images/scheduler/iconModal.svg"
                  alt="static icon"
                  className={styles.profileImage}
                />
              </div>
              <div className={styles.profileDetails}>
                <div className={styles.nameSection}>
                  <h2 className={styles.name}>Ralph Edwards</h2>
                  <button className={styles.viewProfile}>View Profile</button>
                </div>
                <div className={styles.roleSection}>
                  <p className={styles.role}>Plumber</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Expanded Header */}
        {isExpanded && (
          <div className={styles.expandedHeader}>
            <h2 className={styles.expandedTitle}>SMS Communication</h2>
            <button className={styles.closeButton} onClick={handleClose}>
              Close
            </button>
          </div>
        )}

        <div className={styles.mainContent}>
          {/* Chat List - Only shown when expanded */}
          {isExpanded && (
            <div className={styles.chatList}>
              <div className={styles.chatListContent}>
                {chatList.map(chat => (
                  <div
                    key={chat.id}
                    className={`${styles.chatItem} ${
                      selectedChat.id === chat.id ? styles.selectedChat : ''
                    }`}
                    onClick={() => handleChatSelect(chat.id)}
                  >
                    <div className={styles.chatItemImage}>
                      <img src={chat.avatar} alt={chat.name} />
                      {chat.unread && <div className={styles.unreadDot} />}
                    </div>
                    <div className={styles.chatItemInfo}>
                      <h3 className={styles.chatItemName}>{chat.name}</h3>
                      <p className={styles.chatItemRole}>{chat.role}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Messages Section */}
          <div className={styles.messagesSection}>
            <div className={styles.communicationHeader}>
              {!isExpanded ? (
                <>
                  <button className={styles.backButton} onClick={handleClose}>
                    <img src="/images/scheduler/arrow-left.svg" alt="back" />
                  </button>
                  <h3 className={styles.communicationTitle}>SMS Communication</h3>
                  <button className={styles.expandButton} onClick={toggleExpand}>
                    <img src="/images/scheduler/expand.svg" alt="expand" />
                  </button>
                </>
              ) : (
                <div className={styles.profileHeader}>
                  <div className={styles.profileImageContainer}>
                    <img
                      src={selectedChat.avatar}
                      alt={selectedChat.name}
                      className={styles.profileImage}
                    />
                  </div>
                  <div className={styles.profileDetails}>
                    <div className={styles.nameSection}>
                      <h2 className={styles.name}>{selectedChat.name}</h2>
                    </div>
                    <div className={styles.roleSection}>
                      <p className={styles.role}>{selectedChat.role}</p>
                    </div>
                  </div>
                  <button className={styles.viewProfile}>View Profile</button>
                </div>
              )}
            </div>

            <div className={styles.messageContainer} ref={messageContainerRef}>
              <div className={styles.dateLabel}>Today</div>
              <div className={styles.messagesWrapper}>
                {messageList.map(msg => (
                  <MessageBubble key={msg.id} isReceived={msg.isReceived}>
                    {msg.content}
                  </MessageBubble>
                ))}
              </div>
            </div>

            <div className={styles.bottomSection}>
              {isExpanded ? (
                // Expanded state layout
                <div className={`${styles.inputSection} ${styles.inputSectionExpanded}`}>
                  <div className={styles.textareaWrapper}>
                    <textarea
                      className={`${styles.messageInput} ${styles.messageInputExpanded}`}
                      placeholder="Type your message..."
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      rows={5}
                    />
                  </div>
                  <div className={styles.sendButtonWrapper}>
                    <WBButton
                      icon={<i className={styles.iconSend} />}
                      title="Send"
                      color="grey"
                      func={handleSubmit}
                      disabled={!message.trim()}
                    />
                  </div>
                </div>
              ) : (
                // Original collapsed state layout
                <>
                  <div className={styles.inputSection}>
                    <textarea
                      className={`${styles.messageInput} ${styles.messageInputCollapsed}`}
                      placeholder="Type your message..."
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      rows={4}
                    />
                  </div>
                  <div className={styles.modalFooter}>
                    <WBButton
                      title="Cancel"
                      color="grey"
                      func={handleClose}
                      styles={{
                        boxShadow: 'none',
                        border: 'none',
                        background: 'none',
                      }}
                    />
                    <WBButton
                      icon={<i className={styles.iconSend} />}
                      title="Send"
                      color="grey"
                      func={handleSubmit}
                      disabled={!message.trim()}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMSModal;
