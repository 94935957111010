import { useEffect, useState } from "react";
import { WBButton, WBCollector, WBDropdown, WBDropdownOption } from "../../../../Components/UIComponents/WBComponents";
import { PostDB } from "../../../../Components/DBTools";

const AddFilter = ({ closeModalMethod }) => {
    const [filtName, setFiltName] = useState();
    // const [filtDesc, setFiltDesc] = useState();
    const [industry, setIndustry] = useState();
    const [industryName, setIndustryName] = useState();

    const [allIndustries, setAllIndustries] = useState();
    const [allFilters, setAllFilters] = useState();
    const [selectedSubFilters, setSelectedSubFilters] = useState([]);

    const [changesMade, setChangesMade] = useState(false);

    const GetIndustries = async () => {
        const response = await PostDB({
            branch: "/settings/industries",
            json: {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }
            }
        });
        if (response !== undefined && response !== null) {
            setAllIndustries(response);
        }
    }

    useEffect(() => {
        GetIndustries();
    }, []);

    const SaveFilter = async () => {
        if (industry === undefined)
            return;
        let bodyObj = {
            filter_id: null,
            filter_name: filtName,
            trade_id: industry, // I dont think they are bound by trade????
            sub_filters: selectedSubFilters.map((subFilter) => ({
                sub_filter_id: subFilter.id,
                sub_filter_name: subFilter.name,
                status: 1
            })),
            description: "",
            active: 1
        }
        let response = await PostDB({
            branch: "/editFilter", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bodyObj)
            }
        });
        if (response.api_status === "success") {
            alert("Filter has been successfully added");
            closeModalMethod();
        }
        else {
            alert("There was an error saving this Utility. Check your fields and try again");
        }
    }

    const handleBGClick = (e) => {
        const background = document.querySelector(".bg-darkblue");
        if (background === e.target) {
            closeModalMethod();
        }
    }

    return (
        <div className="add-utility-page">
            <div className="bg-darkblue" onClick={(e) => handleBGClick(e)}>
                <div className="content">
                    <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Add Filter</b></h4>
                        </div>
                        <div className="button-row right gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                        </div>
                    </div>
                    <div className="body">
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="filtName" className="form-label">
                                        Trade
                                    </label>
                                    <WBDropdown leftitem={industryName} rightitem={<i className="icon-arrow-down-2"></i>} defaultValue="None" color={"white"}>
                                        {allIndustries && allIndustries.map((industry) => {
                                            return (
                                                <WBDropdownOption title={industry.name} func={() => { setChangesMade(true); setIndustryName(industry.name); setIndustry(industry.id) }} />
                                            )
                                        })}
                                    </WBDropdown>
                                </div>
                            </div>
                        </div>
                        <div className="hr" />
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="filtName" className="form-label">
                                        Filter Name
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <input
                                        type="text"
                                        name="filtName"
                                        id="filtName"
                                        placeholder="enter filter name"
                                        className="half"
                                        defaultValue={filtName}
                                        onChange={(e) => { setChangesMade(true); setFiltName(e.target.value) }}
                                        autoFocus
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="hr" />
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="subFilters" className="form-label">
                                        Sub Filters
                                    </label>

                                    <div className={"pt-10"}>
                                        <WBCollector allCollectorItems={allFilters} selectedTitle="Sub Filter" returnFunc={(e) => { setChangesMade(true); setSelectedSubFilters(e) }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="filtDesc" className="form-label">
                                        Filter Description
                                    </label>
                                    <textarea
                                        name="filtDesc"
                                        className="half"
                                        id="filtDesc"
                                        placeholder="enter filter description"
                                        defaultValue={filtDesc}
                                        rows={"5"}
                                        onChange={(e) => { setChangesMade(true); setFiltDesc(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="footer">
                        <WBButton title="Save" color={"green"} func={() => SaveFilter()} />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AddFilter;