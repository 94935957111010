import { useState } from "react";
import ServiceItems from "../Components/Settings/Services/ServiceItems";
import { WBButton, WBDrawer, WBDropdown, WBDropdownOption, WBIOSToggle, WBLinker, WBModal, WBSelector, WBTable } from "../Components/UIComponents/WBComponents";

const ComponentTest = () => {
    // if (localStorage.getItem("test") !== "complete") { window.location = "/" } // get outta here

    let downArrow = <i className="icon-arrow-down"></i>;
    let threeDots = <div className="title">
        <div className="option">
            <span className="dot-icon"></span>
            <span className="dot-icon"></span>
            <span className="dot-icon"></span>
        </div>
    </div>;

    const myFunc = () => {
        alert("Called from custom button")
    }

    const [modalOpen, setModalOpen] = useState(false);
    const toggleModal1 = () => setModalOpen(!modalOpen);

    const [modalOpen2, setModalOpen2] = useState(false);
    const toggleModal2 = () => setModalOpen2(!modalOpen2);

    const enteredField = "";

    const [service1Linked, setService1Linked] = useState(false);

    const state = "QLD";

    const [iosToggle, setIosToggle] = useState(false);

    return (
        <div className="flex-grid w-full">
            <div className="rows">
                <div className="col">
                    <p>Custom Dropdown Component</p>

                    <WBDropdown leftitem={"Save and Submit for Review"} rightitem={downArrow} color="white" >
                        <WBDropdownOption title="Add to Queue" link="/" />
                        <WBDropdownOption title="Prioritise Send" link="/" />
                    </WBDropdown>
                    <WBDropdown leftitem={"Cancel Job"} rightitem={downArrow} color="red" align="start">
                        <WBDropdownOption title="MyTitle" link="/" />
                    </WBDropdown>
                    <WBDropdown leftitem={"Select one option"} rightitem={downArrow} color="green" align="center" >
                        <WBDropdownOption title="Option 1" link="/" />
                        <WBDropdownOption title="Option 2" link="/" />
                        <WBDropdownOption title="Option 3" link="/" />
                    </WBDropdown>
                    <WBDropdown leftitem={"End Session"} rightitem={downArrow} color="black" align="end"></WBDropdown>
                    <WBDropdown leftitem={"Save Result"} rightitem={downArrow} color="blue" align="start" >
                        <WBDropdownOption title="Save as Submission" link="/" />
                        <WBDropdownOption title="Save as Outcome" link="/" />
                        <WBDropdownOption title="Save as Result" link="/" />
                    </WBDropdown>
                    <WBDropdown leftitem={"Send Result to DB"} rightitem={downArrow} color="grey" align="center" >
                        <WBDropdownOption title="Send to DB1" link="/" />
                        <WBDropdownOption title="Save locally" link="/" />
                    </WBDropdown>
                    <WBDropdown leftitem={threeDots} align="end">
                        <WBDropdownOption title="Edit" />
                        <WBDropdownOption title="View" />
                        <WBDropdownOption title="Delete" />
                    </WBDropdown>
                </div>
                <div className="col">
                    <p>Custom Button Component</p>
                    <WBButton title="Button" color="white" />
                    <WBButton title="Button w/ link redirect" color="red" link="/home" />
                    <WBButton title="Button w/ external function" color="green" func={myFunc} />
                    <WBButton title="Button w/ internal function" color="black" func={() => { alert("This function works inside the button and I printed to console"); console.log("Print this in console log") }} />
                    <WBButton title="Button w/ function and link" color="blue" link="/" func={() => { alert("This function, when closed, will redirect you to the link"); }} />
                    <WBButton title="Button with color &quot;Grey&quot;" color="grey" />
                    <WBButton title="Button with color &quot;Blue and White&quot;" color="blueandwhite" />
                    <WBButton title="Button with color &quot;Red and White&quot;" color="redandwhite" />
                    <WBButton title="Button with color &quot;Blue Border&quot;" color="blueborder" />
                    <WBButton title="Button with color &quot;Blue Border&quot;" color="blueborder" />
                    <WBButton icon={<i className="icon-link" />} color={"white"} />
                    <WBButton icon={<i className="icon-price-book" />} color={"white"} />
                    <WBButton icon={<i className="icon-end" />} color={"white"} />
                </div>
                <div className="col">
                    <p>Custom Modal Component</p>
                    <WBButton title="Toggle Modal" func={toggleModal1} color="white" ></WBButton>
                    {modalOpen && <WBModal title="This is my modal" description="This is the description of this modal. It can wrap around like this for easier reading." closeFunction={toggleModal1} submitFunction={() => console.log(enteredField.current.target.value)}>
                        <label htmlFor="modalTextbox">Please enter a name in the textbox and hit submit</label>
                        <input id="modalTextBox" type="text" placeholder="enter data here" onChange={(e) => enteredField.current = e} />
                    </WBModal>}
                    <WBButton title="Toggle larger modal" func={toggleModal2} color="white" ></WBButton>
                    {modalOpen2 && <WBModal title="This is my modal" description="This is the description of this modal. It can wrap around like this for easier reading." closeFunction={toggleModal2} submitFunction={() => console.log(enteredField.current.target.value)}>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eu auctor felis. Donec scelerisque aliquet aliquam. Nam vel lorem sed quam vehicula feugiat ac ac turpis. Duis dictum efficitur lacus a imperdiet. Proin vehicula lacinia egestas. Vestibulum feugiat, quam quis molestie auctor, arcu est mattis neque, sed malesuada magna lectus nec justo. Duis odio nibh, ultricies porta quam a, tempus aliquam quam. Cras ultricies porttitor nibh, non auctor mi ornare et. Nam consequat rutrum lacus, nec convallis velit porttitor id. Pellentesque ac leo aliquet, ultricies mi a, maximus erat.

                            Pellentesque sodales mauris non nisi auctor, vel condimentum lorem vulputate. Duis posuere porttitor risus id facilisis. Curabitur varius neque sit amet metus efficitur auctor. Integer in arcu at libero pretium iaculis. Vestibulum magna magna, tincidunt ut tortor pulvinar, molestie elementum diam. Morbi molestie sem sapien, id mattis felis venenatis vel. Pellentesque cursus commodo gravida. Praesent convallis, neque condimentum gravida hendrerit, tortor ex consequat turpis, ac tincidunt metus mi et arcu. Maecenas pharetra libero eget purus pulvinar tempus.

                            Pellentesque porttitor nisi quis neque porttitor pulvinar sed sed nunc. Fusce molestie massa quis sapien euismod luctus. Vivamus laoreet scelerisque quam, eget tincidunt enim euismod ac. Nunc sollicitudin felis ut eros cursus vestibulum. Suspendisse rutrum molestie elementum. Donec at odio eu velit sodales accumsan a id quam. Quisque leo neque, tincidunt non interdum eu, bibendum nec velit. Fusce nisl felis, dignissim ac tincidunt sed, suscipit ac neque. Aliquam semper urna a dui pretium, in vehicula nisl dapibus. Integer vel molestie magna. Praesent in nulla lectus. Duis velit nisl, sollicitudin ut odio in, laoreet tristique tortor. Ut a est id nisi feugiat convallis. Vestibulum non pharetra lorem. Vestibulum fringilla, justo vel semper auctor, ligula elit molestie magna, vel efficitur dolor libero quis nibh.

                            Sed at neque accumsan, semper arcu id, aliquet turpis. Proin consectetur accumsan posuere. Quisque semper nulla varius, rhoncus erat eget, semper mauris. Nulla facilisi. Mauris sollicitudin rhoncus nunc et imperdiet. Donec semper nisl eget dolor blandit congue. Vestibulum interdum, orci in tempor bibendum, libero massa maximus felis, sed malesuada dui velit nec sapien. Nam lacus nibh, rhoncus vel nibh eu, rutrum aliquam augue. Aliquam blandit tortor vel est eleifend eleifend. Duis est lectus, tristique ac mauris et, laoreet fermentum magna. Morbi nec nibh pharetra, accumsan metus vel, ultrices quam. Vestibulum quis lectus et ante dapibus ornare. Nullam varius felis ut tortor rhoncus, in cursus libero condimentum. Morbi feugiat non velit in mollis.</p>
                        <label htmlFor="modalTextbox">Please enter a name in the textbox and hit submit</label>
                        <input id="modalTextBox" type="text" placeholder="enter data here" onChange={(e) => enteredField.current = e} />
                        <label htmlFor="modalTextbox2">Please enter a name in the textbox and hit submit</label>
                        <input id="modalTextBox2" type="text" placeholder="enter data here" onChange={(e) => enteredField.current = e} />
                    </WBModal>}
                </div>
            </div>
            <div className="rows">
                <div className="col">
                    <p>Custom Table Component (The data inside the table is too modular to be part of the component and must be rendered as children objects)</p>
                    <div className="tempdivfortesting">
                        <WBTable tableData="/servicesJson" tableHeaders={["Image", "Code", "Service", "Price Range", "", "", "Action"]}>
                            <ServiceItems />
                        </WBTable>
                    </div>
                </div>
            </div>
            <div className="rows">
                <div className="col">
                    <p>Custom Selector #1 (No Prices, No Sub Items)</p>
                    <div className="tempdivfortesting">
                        <WBSelector title="Filter" data="/filtersByIndustry" industry="1" identifiers={["filter_id", "filter_name"]} subIdentifiers={["filter_id", "sub_filter_id", "sub_filter_name"]} />
                    </div>
                </div>
                <div className="col">
                    <p>Custom Selector #2 (with Prices)</p>
                    <div className="tempdivfortesting">
                        <WBSelector title="Material" data="/materialsByKeyword" industry="1" identifiers={["id", "title", "cost"]} />
                    </div>
                </div>
            </div>
            <div className="rows">
                <div className="col">
                    <p>Custom Selector #3 (with Sub Items) (Side-by-side)</p>
                    <div className="tempdivfortesting">
                        <WBSelector title="Location Area" data="/getarea" identifiers={["id", ["locality", "state", "postcode"]]} modifiers={{ "state": state }} side />
                    </div>
                </div>
                <div className="col">
                    <p>Custom Selector #3 (with Sub Items) (Side-by-side) (No Search Option)</p>
                    <div className="tempdivfortesting">
                        <WBSelector title="Location Area" data="/getarea" identifiers={["id", ["locality", "state", "postcode"]]} modifiers={{ "state": state }} side nosearch />
                    </div>
                </div>
            </div>
            <div className="rows">
                <div className="col">
                    <p>Custom Drawer</p>
                    <div className="tempdivfortesting">
                        <WBDrawer />
                        <WBDrawer title={"BPAY"} active >
                            <div className="testcontent">
                                <p>This is my content</p>
                            </div>
                        </WBDrawer>
                        <WBDrawer title={"Working Area"} subtitle={"Revesby"} inactive >
                            <div className="testcontent">
                                <p>This is my content</p>
                            </div>
                        </WBDrawer>
                        <WBDrawer title={"Location"} subtitle={"Sydney"} active >
                            <div className="testcontent">
                                <p>This is my content</p>
                            </div>
                        </WBDrawer>
                    </div>
                </div>
            </div>
            <div className="rows">
                <div className="col">
                    <p>Custom Drawer (Small)</p>
                    <div className="tempdivfortesting">
                        <WBDrawer small />
                        <WBDrawer small title={"Sydney Plumbing, Electrical"} active >
                            <div className="testcontent">
                                <p>This is my content</p>
                            </div>
                        </WBDrawer>
                        <WBDrawer small title={"Central Coast Electrical"} inactive >
                            <div className="testcontent">
                                <p>This is my content</p>
                            </div>
                        </WBDrawer>
                        <WBDrawer small title={"Testing"} active >
                            <div className="testcontent">
                                <p>This is my content</p>
                            </div>
                        </WBDrawer>
                        <WBDrawer small title={"Default"} active >
                            <div className="testcontent">
                                <p>This is my content</p>
                            </div>
                        </WBDrawer>
                    </div>
                </div>
            </div>
            <div className="rows">
                <div className="col">
                    <p>Customer Linker (the custom div container is conflicting with css in the title to align to the right. This is working normally outside of this test page)</p>
                    <div className="tempdivfortesting">
                        <WBLinker title="Hello" image="haha" />
                        <WBLinker title="Hello" linked={service1Linked ? true : false} func={() => setService1Linked(!service1Linked)} />
                    </div>
                </div>
            </div>
            <div className="rows">
                <div className="col">
                    <p>Custom IOS Toggle</p>
                    <div className="tempdivfortesting flex" >
                        <WBIOSToggle active={iosToggle} func={() => setIosToggle(!iosToggle)} />
                        <p>Toggle status: {iosToggle ? "ON" : "OFF"}</p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ComponentTest;