import avatar from '../assets/avatar-md.svg';

export const techniciansData = {
  Plumbers: [
    {
      firstName: 'Theresa',
      lastName: 'Webb',
      id: 'KWVT-LD',
      phone: '0466 305 939',
      image: avatar,
      status: 'Restock Required',
    },
    {
      firstName: 'Cody',
      lastName: 'Fisher',
      id: 'KOXI-CD2',
      phone: '0466 892 113',
      image: avatar,
    },
    {
      firstName: 'Kristin',
      lastName: 'Watson',
      id: 'KATU-DT3',
      phone: '0466 445 778',
      image: avatar,
      status: 'Restock Required',
    },
    {
      firstName: 'Ralph',
      lastName: 'Edwards',
      id: 'OPB World',
      phone: '0466 667 223',
      image: avatar,
    },
    {
      firstName: 'Eleanor',
      lastName: 'Pena',
      id: 'KVDO QVC',
      phone: '0466 334 556',
      image: avatar,
      status: 'Low Stock',
      badge: 'APU 89797789',
    },
  ],
  Electricians: [
    {
      firstName: 'Jerome',
      lastName: 'Bell',
      id: 'OPB PBS Kids',
      phone: '0466 112 445',
      image: avatar,
    },
    {
      firstName: 'Annette',
      lastName: 'Black',
      id: 'KOIN HDTV',
      phone: '0466 778 990',
      image: avatar,
    },
    {
      firstName: 'Bessie',
      lastName: 'Cooper',
      id: 'KUNPDT2',
      phone: '0466 223 445',
      image: avatar,
      status: 'Low Stock',
    },
    {
      firstName: 'Darrell',
      lastName: 'Steward',
      id: 'KATU-DT1',
      phone: '0466 556 778',
      image: avatar,
    },
  ],
  HVAC: [
    {
      firstName: 'Michael',
      lastName: 'Thompson',
      id: 'HVAC-001',
      phone: '0466 889 001',
      image: avatar,
    },
    {
      firstName: 'Sarah',
      lastName: 'Martinez',
      id: 'HVAC-002',
      phone: '0466 889 002',
      image: avatar,
      status: 'Low Stock',
      badge: 'APU 89797729',
    },
  ],
};
