import styles from '../runreports.module.css';

const SidebarBox = ({title, items}) => {
  return (
    <div className={styles.sidebarBox}>
      <p className={styles.boldSidebar}>{title}</p>
      {items.map((item, index) => (
        <p key={index}>{item}</p>
      ))}
    </div>
  );
};

export default SidebarBox;
