import {useContext} from 'react';
import styles from './TablePagination.module.css';
import {AccountingContext} from '../../../context/AccountingContext';

const TablePagination = () => {
  const {currentPage, totalPages, handlePageChange} = useContext(AccountingContext);

  if (totalPages <= 1) return null;

  const renderPageNumbers = () => {
    const pages = [];

    // Always show first page
    pages.push(
      <button
        key={1}
        onClick={() => handlePageChange(1)}
        className={`${styles.pageButton} ${currentPage === 1 ? styles.active : ''}`}
        aria-label={`Page 1`}
        aria-current={currentPage === 1 ? 'page' : undefined}
      >
        1
      </button>
    );

    let startPage = Math.max(2, currentPage - 1);
    let endPage = Math.min(totalPages - 1, currentPage + 1);

    // Add first ellipsis if needed
    if (startPage > 2) {
      pages.push(
        <span key="ellipsis1" className={styles.pageButton}>
          ...
        </span>
      );
    }

    // Add middle pages
    for (let i = startPage; i <= endPage; i++) {
      if (i === 1 || i === totalPages) continue;
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`${styles.pageButton} ${currentPage === i ? styles.active : ''}`}
          aria-label={`Page ${i}`}
          aria-current={currentPage === i ? 'page' : undefined}
        >
          {i}
        </button>
      );
    }

    // Add last ellipsis if needed
    if (endPage < totalPages - 1) {
      pages.push(
        <span key="ellipsis2" className={styles.pageButton}>
          ...
        </span>
      );
    }

    // Always show last page if more than 1 page exists
    if (totalPages > 1) {
      pages.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={`${styles.pageButton} ${currentPage === totalPages ? styles.active : ''}`}
          aria-label={`Page ${totalPages}`}
          aria-current={currentPage === totalPages ? 'page' : undefined}
        >
          {totalPages}
        </button>
      );
    }

    return pages;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  return (
    <div className={styles.pagination}>
      <button
        className={styles.pageButton}
        onClick={handlePrevious}
        disabled={currentPage === 1}
        aria-label="Previous page"
      >
        <i className="icon-arrowLeft"></i>
      </button>

      {renderPageNumbers()}

      <button
        className={styles.pageButton}
        onClick={handleNext}
        disabled={currentPage === totalPages}
        aria-label="Next page"
      >
        <i className="icon-arrowRight"></i>
      </button>
    </div>
  );
};

export default TablePagination;
