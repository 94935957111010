import styles from './TruckOrdersTable.module.css';
import StatusBadge from '../../../../../Components/StatusBadge/StatusBadge';

const TruckOrdersTable = () => {
  const truckOrders = [
    {
      technician: 'Eleanor Pena',
      registration: 'KVDO QVC',
      truckStatus: 'Low Stock',
      poStatus: 'Awaiting',
      currentQty: 1,
    },
    {
      technician: 'Kristin Watson',
      registration: 'KATU-DT3',
      truckStatus: 'Restock Required',
      poStatus: 'Awaiting',
      currentQty: 2,
    },
    // Add more sample data as needed
  ];

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableHeader}>
        <h2>Truck Orders Status</h2>
      </div>

      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Technician</th>
              <th>Registration</th>
              <th></th>
              <th>Truck Status</th>
              <th>PO Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {truckOrders.map((order, index) => (
              <tr key={index}>
                <td>{order.technician}</td>
                <td>{order.registration}</td>
                <td></td>
                <td>
                  <StatusBadge status={order.truckStatus} />
                </td>
                <td>
                  <StatusBadge status={order.poStatus} />
                </td>
                <td>
                  <i className="icon-more-options"></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TruckOrdersTable;
