import styles from './AccountingTable.module.css';
import TablePagination from './TablePagination/TablePagination';
import {WBButton} from '../../../../Components/UIComponents/WBComponents';
import {useContext} from 'react';
import {AccountingContext} from '../../context/AccountingContext';
import {JobCardModalContext} from '../../../../Components/Customer/context/JobCardModalContext';

const AccountingTable = () => {
  const {filteredData, handleSearch} = useContext(AccountingContext);
  const {openModal} = useContext(JobCardModalContext);

  const handleSearchChange = e => {
    handleSearch(e.target.value);
  };

  const handleFilterClick = () => {
    console.log('Filter clicked');
  };

  const handleReceiptClick = (rowData) => {
    openModal('Job Card', rowData);
  };

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableHeader}>
        <div className={styles.searchControls}>
          <div className={styles.searchWrapper}>
            <span className={styles.searchIcon}>
              <i className="icon-search" />
            </span>
            <input
              type="text"
              placeholder="Start typing (job id, customer details, etc.)"
              onChange={handleSearchChange}
              aria-label="Search invoices"
            />
          </div>
          <WBButton
            title="Filters"
            icon={<i className="icon-filter" />}
            color="white"
            func={handleFilterClick}
            height="45px"
            styles={{
              border: '1px solid #D8D8D8',
              borderRadius: '10px',
              padding: '12px 20px',
              fontSize: '16px',
              gap: '10px',
            }}
          />
        </div>
      </div>

      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Invoice Number</th>
              <th>Job Number</th>
              <th>Sale Date</th>
              <th>Customer</th>
              <th>Amount</th>
              <th>Paid</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, index) => (
              <tr key={index}>
                <td>{row.invoiceNumber}</td>
                <td>{row.jobNumber}</td>
                <td>{row.saleDate}</td>
                <td>{row.customer}</td>
                <td>{row.amount}</td>
                <td>{row.paid}</td>
                <td>
                  <WBButton
                    title={row.receipt}
                    icon={<i className="icon-briefcase" />}
                    color="white"
                    func={() => handleReceiptClick(row)}
                    height="40px"
                    styles={{
                      border: '1px solid #D8D8D8',
                      borderRadius: '10px',
                      padding: '12px 20px',
                      fontSize: '16px',
                      gap: '6px',
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <TablePagination />
    </div>
  );
};

export default AccountingTable;
