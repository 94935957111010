import styles from '../runreports.module.css';
import SidebarBox from './SidebarBox';

const ReportsSidebar = () => {
  const reportSections = [
    {
      title: 'Sales',
      items: [
        'Number of Sales',
        'Sales by Month',
        'List of Sales',
        'Sales payment',
      ],
    },
    {
      title: 'Jobs',
      items: [
        'Jobs over 10k',
        'Jobs by Month',
        'Emergency Jobs',
        'Job Cost',
        'Jobs Scheduled & Under $1,200',
      ],
    },
    {
      title: 'Clients',
      items: [
        'List of Clients',
        'Clients VIP over $1,000',
        'List of Sales',
        'Sales payments',
        'VIP customers without emails',
        'All customers with emails',
        'VIP customers with emails',
      ],
    },
    {
      title: 'Custom Reports',
      items: ['Sales greater than callout fee $89'],
    },
  ];

  return (
    <div className={styles.sidebarContainer}>
      <p className={styles.reportsTitle}>Reports</p>
      {reportSections.map((section, index) => (
        <SidebarBox key={index} title={section.title} items={section.items} />
      ))}
    </div>
  );
};

export default ReportsSidebar;
