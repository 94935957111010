import React, { useState, useCallback, useEffect } from 'react';
import styles from './schedulercalendar.module.css';
import ScheduledBox from './components/ScheduledBox';
import SchedulerModal from './SchedulerModal';
import useDragAndDrop from './hooks/useDragAndDrop';
import useTimeIndicator from './hooks/useTimeIndicator';
import { useSchedule } from './context/ScheduleContext';

const TIME_SLOTS = [
  '07:00 am',
  '08:00 am',
  '09:00 am',
  '10:00 am',
  '11:00 am',
  '12:00 pm',
  '01:00 pm',
  '02:00 pm',
  '03:00 pm',
  '04:00 pm',
];

// Extract calendar box component
const CalendarBox = React.memo(
  ({ index, rowHeight, rowIndex, schedulesAtPosition, isGridLocked, ...props }) => (
    <div
      key={index}
      className={styles.calendarBox}
      style={{ minHeight: `${rowHeight}px` }}
      onDragOver={!isGridLocked ? props.handleDragOver : null}
      onDragLeave={!isGridLocked ? props.handleDragLeave : null}
      onDrop={!isGridLocked ? event => props.handleDrop(event, index, rowIndex) : null}
    >
      {schedulesAtPosition.map(schedule =>
        index === schedule.startIndex ? (
          <ScheduledBox
            key={schedule.id}
            {...schedule}
            draggable={!isGridLocked}
            onDragStart={!isGridLocked ? event => props.handleDragStart(event, schedule) : null}
            onClick={() => props.openScheduleModal(schedule)}
            stackIndex={schedulesAtPosition.indexOf(schedule)}
            isLastInStack={schedulesAtPosition.indexOf(schedule) === schedulesAtPosition.length - 1}
            location={schedule.location}
            issue={schedule.issue}
            description={schedule.description}
          />
        ) : null
      )}
    </div>
  )
);

const SchedulerCalendar = ({
  isGridLocked,
  schedules,
  setSchedules,
  onScheduleMove,
  workers = []
}) => {
  const { openTechProfileModal, techProfileModal, smsModal } = useSchedule();


  const [isModalOpen, setModalOpen] = useState(false);
  const [activeSchedule, setActiveSchedule] = useState(null);
  const currentTimePosition = useTimeIndicator(styles);

  const openScheduleModal = schedule => {
    setActiveSchedule(schedule);
    setModalOpen(true);
  };

  useEffect(() => {
    console.log('isGridLocked', isGridLocked);
  }, [isGridLocked]);

  const { handleDragStart, handleDragOver, handleDragLeave, handleDrop } = useDragAndDrop(
    setSchedules,
    onScheduleMove,
    styles
  );

  // Memoize getScheduleAtPosition calculations
  const getScheduleAtPosition = useCallback(
    (rowIndex, index) => {
      const rowSchedules = schedules.filter(schedule => schedule.rowIndex === rowIndex);

      const schedulesAtCurrentPosition = rowSchedules.filter(
        schedule => schedule.startIndex <= index && index < schedule.startIndex + schedule.span
      );

      const allOverlappingSchedules = new Set();

      schedulesAtCurrentPosition.forEach(currentSchedule => {
        const currentEnd = currentSchedule.startIndex + currentSchedule.span;

        rowSchedules.forEach(schedule => {
          const scheduleStart = schedule.startIndex;
          const scheduleEnd = schedule.startIndex + schedule.span;

          if (scheduleStart < currentEnd && scheduleEnd > currentSchedule.startIndex) {
            allOverlappingSchedules.add(schedule);
          }
        });
      });

      return Array.from(allOverlappingSchedules).sort((a, b) => a.startIndex - b.startIndex);
    },
    [schedules]
  );

  // Calculate row height helper
  const calculateRowHeight = useCallback(
    rowIndex => {
      const schedulesInRow = schedules.filter(schedule => schedule.rowIndex === rowIndex);
      const maxStackIndex = Math.max(
        ...schedulesInRow.map(schedule => {
          const overlappingSchedules = getScheduleAtPosition(rowIndex, schedule.startIndex);
          return overlappingSchedules.length;
        }),
        0
      );

      return Math.max(65, (maxStackIndex - 1) * 24 + 55 + 10);
    },
    [getScheduleAtPosition, schedules]
  );

  const renderCalendarRow = useCallback(
    rowIndex => {
      const rowHeight = calculateRowHeight(rowIndex);
      const worker = workers[rowIndex - 2]; // Adjust index since rows start at 2

      return (
        <div
          className={styles.calendarRowWrapper}
          style={{ minHeight: `${rowHeight}px` }}
        >
          <div
            className={styles.calendarRow}
            style={{ minHeight: `${rowHeight}px` }}
          >
            <div
              className={styles.calendarFirstBox}
              style={{ minHeight: `${rowHeight}px` }}
              onDoubleClick={(event) => openTechProfileModal({
                name: 'Ralph Edwards',
                role: 'Plumber',
              }, rowIndex, event)}
            >
              <div className={`${styles.calendarFirstBoxContent} ${(techProfileModal.selectedTechId === rowIndex &&
                (techProfileModal.isOpen || smsModal.isOpen))
                ? styles.calendarFirstBoxContentActive
                : ''
                }`}>
                <img className={styles.calendarFirstBoxIcon} src="/images/scheduler/iconModal.svg" alt="user_icon" />

                <div className={styles.calendarNames}>
                  <p className="bold">{worker?.firstName || 'N/A'}</p>
                  <p>{worker?.lastName || ''}</p>
                  <p className={styles.calendarStatus}>{worker?.status || 'Unknown'}</p>
                </div>
              </div>
              {worker?.apprentices?.length > 0 && (
                <div className={styles.apprenticesContainer}>
                  {worker.apprentices.map((apprentice, index) => (
                    <div key={apprentice.id} className={styles.apprenticeCircle}>
                      <img src={apprentice.imageUrl} alt={`apprentice_${index + 1}`} />
                    </div>
                  ))}
                </div>
              )}
            </div>
            {[...Array(10)].map((_, index) => (
              <CalendarBox
                key={index}
                index={index}
                rowHeight={rowHeight}
                rowIndex={rowIndex}
                isGridLocked={isGridLocked}
                schedulesAtPosition={getScheduleAtPosition(rowIndex, index)}
                handleDragOver={handleDragOver}
                handleDragLeave={handleDragLeave}
                handleDrop={handleDrop}
                handleDragStart={handleDragStart}
                openScheduleModal={openScheduleModal}
              />
            ))}
          </div>
        </div>
      );
    },

    [
      calculateRowHeight,
      getScheduleAtPosition,
      handleDragOver,
      handleDragLeave,
      handleDrop,
      handleDragStart,
      openScheduleModal,
      isGridLocked,
      openTechProfileModal,
      techProfileModal.selectedTechId,
      techProfileModal.isOpen,
      smsModal.isOpen,
      workers
    ]
  );

  const renderCalendarBox = (index, rowIndex, rowHeight) => {
    const schedulesAtPosition = getScheduleAtPosition(rowIndex, index);

    return (
      <div
        key={index}
        className={styles.calendarBox}
        style={{ minHeight: `${rowHeight}px` }}
        onDragOver={!isGridLocked ? handleDragOver : null}
        onDragLeave={!isGridLocked ? handleDragLeave : null}
        onDrop={!isGridLocked ? event => handleDrop(event, index, rowIndex) : null}
      >
        {schedulesAtPosition.map(schedule =>
          index === schedule.startIndex ? (
            <ScheduledBox
              key={schedule.id}
              {...schedule}
              draggable={!isGridLocked}
              onDragStart={!isGridLocked ? event => handleDragStart(event, schedule) : null}
              onClick={() => openScheduleModal(schedule)}
              stackIndex={schedulesAtPosition.indexOf(schedule)}
              isLastInStack={
                schedulesAtPosition.indexOf(schedule) === schedulesAtPosition.length - 1
              }
              location={schedule.location}
              issue={schedule.issue}
              description={schedule.description}
            />
          ) : null
        )}
      </div>
    );
  };

  return (
    <div className={styles.calendarContainer}>
      <div className={styles.timeRow}>
        <div className={styles.timeSlotsFirstBox}></div>
        {TIME_SLOTS.map((timeSlot, index) => (
          <div key={index} className={`${styles.calendarBox} ${styles.calendarBoxCenter}`}>
            <p>{timeSlot}</p>
          </div>
        ))}
      </div>
      {currentTimePosition > 136 && (
        <div
          className={styles.currentTimeIndicator}
          style={{
            left: `${currentTimePosition}px`,
            position: 'absolute',
            top: 0,
            height: '100%',
          }}
        >
          <div className={styles.currentTimeIndicatorDot} />
        </div>
      )}
      <div className={`${styles.calendarJobTitleRow} ${styles.margin10}`}>
        <p>Plumbing</p>
      </div>
      {[2, 3, 4, 5, 6].map(rowIndex => renderCalendarRow(rowIndex))}
      <div className={styles.calendarJobTitleRow}>
        <p>Electrical</p>
      </div>
      {[7, 8, 9, 10].map(rowIndex => renderCalendarRow(rowIndex))}
      {isModalOpen && (
        <SchedulerModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          scheduleData={activeSchedule}
        />
      )}
    </div>
  );
};

export default SchedulerCalendar;
