import styles from './Breadcrumbs.module.css';

const Breadcrumbs = ({items}) => {
  return (
    <div className={styles.breadcrumbs}>
      <ul>
        {items.map((item, index) => (
          <li key={index} className={styles.breadcrumbItem}>
            {index > 0 && <i className={styles.iconBreadCrumbsArrow}></i>}
            <span>{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Breadcrumbs;
