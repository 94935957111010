import React from 'react';
import styles from '../schedulercalendar.module.css';

const ScheduledBox = ({
  startIndex,
  span,
  left,
  status,
  onClick,
  onDragStart,
  stackIndex = 0,
  isLastInStack = false,
  draggable,
  location,
  issue,
  description,
}) => {
  const width = `calc(${span * 100}% - 5px)`;
  const boxStyle = {
    width: width,
    left: `${2.5}px`,
    top: `${stackIndex * 24}px`,
    height: '55px',
    zIndex: stackIndex + 1,
    cursor: draggable ? 'move' : 'not-allowed',
  };

  const getBoxColorStyle = status => {
    switch (status) {
      case 'scheduled':
        return {backgroundColor: 'rgba(255, 242, 242, 1)'};
      case 'completed':
        return {backgroundColor: 'rgba(229, 253, 241, 1)'};
      case 'started':
        return {backgroundColor: 'rgba(242, 249, 255, 1)'};
      case 'ongoing':
        return {backgroundColor: 'rgba(255, 249, 235, 1)'};
      default:
        return {};
    }
  };

  const getLabelColorStyle = status => {
    switch (status) {
      case 'scheduled':
        return {backgroundColor: 'rgba(233, 98, 98, 1)'};
      case 'completed':
        return {backgroundColor: 'rgba(21, 195, 111, 1)'};
      case 'started':
        return {backgroundColor: 'rgba(0, 148, 255, 1)'};
      case 'ongoing':
        return {backgroundColor: 'rgba(255, 164, 28, 1)'};
      default:
        return {backgroundColor: 'rgba(233, 98, 98, 1)'};
    }
  };

  const boxColorStyle = getBoxColorStyle(status);
  const labelColorStyle = getLabelColorStyle(status);

  const handleDoubleClick = e => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <div
      className={`${styles.scheduledBox} ${styles[status]}`}
      style={{
        gridColumnStart: startIndex + 1,
        gridColumnEnd: `span ${span}`,
        left: `${left}%`,
        ...boxStyle,
        ...boxColorStyle,
      }}
      draggable="true"
      onDragStart={onDragStart}
      onDoubleClick={handleDoubleClick}
    >
      <p className={styles.scheduledBoxTitle}>{location || 'Unknown Location'}</p>
      <p className={styles.scheduledBoxSubtitle}>{issue || description || 'No Description'}</p>
      <div className={styles.scheduledBoxLabel} style={labelColorStyle}>
        <p>{status ? status.charAt(0).toUpperCase() + status.slice(1) : 'N/A'}</p>
      </div>
    </div>
  );
};

export default ScheduledBox;
