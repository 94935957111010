import { WBDropdown, WBDropdownOption, useWBTableData } from "../../UIComponents/WBComponents";
import { PostDB } from "../../DBTools";
import { useState, useEffect, useCallback } from 'react';

const FilterItems = ({ editFunc, filterSelected }) => {
    const deleteFilter = () => { };

    const [filtersFormatted, formatFilters] = useState(<></>);
    const [allIndustries, setAllIndustries] = useState(null);

    const filters = useWBTableData();

    const GetIndustries = async () => {
        let response = await PostDB({
            branch: "/settings/industries",
            json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        });
        if (response !== null) {
            setAllIndustries(response);
        }
    }

    // const FindIndustry = useCallback((id) => {
    //     debugger;
    //     let industry = allIndustries.find(industry => industry.id === id);
    //     if (industry) {
    //         return industry.name;
    //     } else {
    //         return "No Industry";
    //     }
    // }, [allIndustries]);

    useEffect(() => {
        GetIndustries();
    }, []);

    useEffect(() => {
        if (allIndustries && filters !== null && filters !== undefined) {
            if (filters.filters.length > 0) {
                formatFilters(
                    filters.filters.map((filter) => (
                        <div key={filter.filter_id} className="row" id={filter.filter_id} onDoubleClick={() => { filterSelected(filter.filter_id); editFunc(); }}>
                            <div className="cell" title={filter.name}>{filter.name}</div>
                            {/* <div className="cell description" title={filter.description}>{filter.description}</div> */}
                            <div className="cell">{allIndustries.find(industry => industry.id === filter.industry).name}</div>
                            <div className="cell">{filter.status === 1 ? "Active" : "Not Active"}</div>
                            <div className="cell">
                                <WBDropdown leftitem={
                                    <div className="title">
                                        <div className="option">
                                            <span className="dot-icon"></span>
                                            <span className="dot-icon"></span>
                                            <span className="dot-icon"></span>
                                        </div>
                                    </div>
                                } align="end">
                                    <WBDropdownOption title="Edit" func={() => { filterSelected(filter.sub_filter_id); editFunc(); }} />
                                    <WBDropdownOption title="Delete" func={() => deleteFilter(filter.sub_filter_id)} disabled />
                                </WBDropdown>
                            </div>
                        </div>
                    ))
                )
            } else {
                formatFilters(<div>No items found</div>);
            }
        } else {
            formatFilters(<div>Loading...</div>);
        }
    }, [filters, allIndustries, editFunc, filterSelected]);

    return (
        <div className="body" id="bodyData">{filtersFormatted}</div>
    )
}

export default FilterItems;
