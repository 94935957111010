import {useContext} from 'react';
import {JobCardModalContext} from '../../context/JobCardModalContext';
import JobCardPage from '../../../../Pages/Customer/JobCard/JobCardPage';
import {JobCardProvider} from '../../context/JobCardContext';
import styles from './JobCardModal.module.css';

const JobCardModal = () => {
  const {isModalVisible, closeModal, modalTitle, modalData} = useContext(JobCardModalContext);

  if (!isModalVisible) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <h1 className={styles.modalTitle}>{modalTitle}</h1>
          <button className={styles.closeButton} onClick={closeModal}>
            <i className={styles.iconPlus}></i>
          </button>
        </div>
        <div className={styles.modalContent}>
          <JobCardProvider>
            <JobCardPage isModal={true} modalData={modalData} />
          </JobCardProvider>
        </div>
      </div>
    </div>
  );
};

export default JobCardModal;
